import React from "react";

import { TableCell } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  headerTableRow: {
    fontSize: "1.08rem !important",
    color: "#5a5c62 !important",
    textAlign: "left",
    borderBottom: "1px solid #000000 !important",
    fontWeight: "600",
    backgroundColor: "#2d2d2d",
    whiteSpace: "nowrap",
  },
  dataTableBodyBoughtAndSold: {
    background: "#1D4336 !important",
    "& td:first-child": {
      borderStyle: "solid",
      borderColor: "#00FF90",
      borderTopWidth: "0.5px",
      borderBottomWidth: "0.5px",
      borderLeftWidth: "0.5px",
      borderRightWidth: "0px",
    },
    "& td:last-child": {
      borderStyle: "solid",
      borderColor: "#00FF90",
      borderTopWidth: "0.5px",
      borderBottomWidth: "0.5px",
      borderLeftWidth: "0px",
      borderRightWidth: "0.5px",
    },
    "& td:not(:first-child):not(:last-child) ": {
      borderStyle: "solid",
      borderColor: "#00FF90",
      borderTopWidth: "0.5px",
      borderBottomWidth: "0.5px",
      borderLeftWidth: "0px",
      borderRightWidth: "0px",
    },
  },
  dataTableBody: {
    "& tr:nth-of-type(odd)": {
      backgroundColor: "#222222 ",
      "&:hover": {
        backgroundColor: "#535353 !important",
      },
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: "#2d2d2d",
      "&:hover": {
        backgroundColor: "#535353 !important",
      },
    },
  },
  dataTableCell: {
    fontSize: "13px !important",
    color: "#ffffff !important",
    textAlign: "left",
    borderBottom: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  tableStyle: {
    // height: "calc(100vh - 210px)",
    "@media (max-width: 1250px)": {
      height: "calc(100vh - 210px)",
    },
    "@media (min-width: 1251px)": {
      height: "calc(100vh - 210px)",
    },
    "@media (min-width: 2559px)": {
      height: "calc(100vh - 210px)",
    },
    "@media (max-width: 900px)": {
      height: "calc(100vh - 250px)",
    },
    overflow: "auto",
  },
}));
export const StyledTableCell = styled((props) => <TableCell {...props} />)({
  "&:hover": {
    cursor: "pointer",
  },
});
