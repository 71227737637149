import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  viewAs: {
    marginTop: "18px !important",
    fontSize: "1.3rem !important",
    color: "#FFFFFF !important",
  },
  viewAsGrid: {
    "@media (max-width: 1280px)": {
      display: "none",
    },
  },
  selectOutline: {
    "&.MuiOutlinedInput-root": {
      color: "#ffffff",
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#494c55",
      },
    },
    "& .MuiSvgIcon-root": {
      color: "#54a9ff",
    },
  },
}));
