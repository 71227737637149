import React from "react";
import { Grid, Typography } from "@mui/material";
import chromeLogo from "../../../assets/icons/chrome-logo.png";
const ChangeBrowser = () => {
  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid
        container
        item
        xs={4}
        lg={3}
        spacing={2}
        justifyContent="center"
        alignItems="center"
        style={{
          background: " #343536 0% 0% no-repeat padding-box",
          borderRadius: "4px",
          boxShadow: "0px 3px 18px #00000040",
        }}
      >
        <Grid item xs={12}>
          <Typography
            style={{
              color: "#ffffff",
              fontSize: "1.3125rem",
              textAlign: "center",
            }}
          >
            This browser is currently not supported
          </Typography>
        </Grid>
        <Grid item>
          <Typography style={{ color: "#ffffff", fontSize: "1.3125rem" }}>
            Please use latest version of Google Chrome{" "}
          </Typography>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          sx={{ paddingBottom: "10px" }}
        >
          <img
            style={{ width: "50px", height: "50px" }}
            src={chromeLogo}
            alt="chromeLogo"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangeBrowser;
