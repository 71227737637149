import React from "react";
import { makeStyles } from "@mui/styles";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

export const useStyles = makeStyles((theme) => ({
  sideBarIcons: {
    padding: "20px",
    borderRadius: "0 4px 4px 0",
  },
  sideBarSx: {
    width: "60px",
    left: "0px",
    height: "100vh",
    backgroundColor: "#343536",
    borderRadius: "0 8px 8px 0",
  },
  logoutToolTip: {
    fontSize: "16px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  tooltip: {
    fontSize: "20px",
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    height : 20,
    fontSize: theme.typography.pxToRem(20),
  },
}));