import {
  Grid,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import SearchInput from "../../../ReusableComps/SearchInput/SearchInput";
import { useStyles } from "./Instruments.style";
import Loader from "../../../ReusableComps/loader/Loader";

const InstrumentsView = (props) => {
  const {
    filterBonds,
    openNewOrEditDialog,
    downloadBondsCsvFile,
    tableHeaders,
    sortedBonds,
    loadingBonds,
    editBond,
    bonds,
  } = props;
  const classes = useStyles();

  return (
    <Grid item container style={{ padding: "16px" }}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Button className={classes.editButton} onClick={editBond}>
            Edit
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item style={{ paddingRight: "12px" }}>
            <IconButton
              className={classes.downloadButton}
              onClick={downloadBondsCsvFile}
            >
              <DownloadIcon style={{ color: "#54A9FF" }} />
            </IconButton>
          </Grid>
          <Grid item>
            <SearchInput onChange={(e) => filterBonds(e)} />
          </Grid>
        </Grid>
      </Grid>
      {sortedBonds && sortedBonds.length && loadingBonds !== true ? (
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <TableContainer
            className={classes.tableStyle}
            style={{ border: "1px solid #3D3F47" }}
            sx={{ height: "calc(100vh - 210px)" }}
          >
            <Table stickyHeader style={{ width: "inherit" }} size="small">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => {
                    return (
                      <TableCell
                        key={index}
                        className={classes.headerTableRow}
                        style={{
                          textTransform: "capitalize",
                        }}
                      >
                        {header}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.dataTableBody}>
                {sortedBonds.map((bond, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.dataTableCell}
                        style={{
                          maxWidth: "100px",
                          textTransform: "capitalize",
                        }}
                      >
                        {bond.region}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {bond.isin}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {bond.bond_name}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {bond.currency}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid
          item
          container
          justifyContent="center"
          style={{ position: "relative", top: "340px" }}
        >
          <Grid item>
            <Loader />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default InstrumentsView;
