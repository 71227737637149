import {
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@mui/material";
  import React, { useState } from "react";
  import { StyledTableCell, useStyles } from "./SystemLogs.style";
  import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
  import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
  import "./SystemLogs.css";
  function SystemLogsView(props) {
    const classes = useStyles();
    const {
      headers,
      tableData,
      handleSorting,
      sortedField,
      tableContainerSx,
      component,
      fullView = null,
      permission
    } = props;
    // className={isTraded ? "tradedBonds" : ""}>
    return (
      <TableContainer
        className={classes.tableStyle}
        style={{ border: "1px solid #3D3F47" }}
        sx={tableContainerSx ? tableContainerSx : null}
      >
        <Table stickyHeader style={{ width: "inherit" }} size="small">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return (
                  <StyledTableCell
                    key={index}
                    className={classes.headerTableRow}
                    style={{
                      textTransform: "capitalize",
                    }}
                    onClick={() => handleSorting(header)}
                  >
                    {header}
                    <IconButton
                      size="small"
                      style={{
                        color: "#bababa",
                        fontSize: "0.875rem",
                        marginLeft: 10,
                      }}
                      onClick={() => handleSorting(header)}
                    >
                      {sortedField.fieldName === header ? (
                        sortedField.asd ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        )
                      ) : (
                        <div style={{ width: "18px", height: "18px" }} />
                      )}
                    </IconButton>
                  </StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody className={classes.dataTableBody}>
            {tableData?.length === 0 ? (
              <TableRow>
                <TableCell
                  className={classes.dataTableCell}
                  colSpan="12"
                  style={{
                    textAlign: "center",
                  }}
                >
                  No Data
                </TableCell>
              </TableRow>
            ) : (
              tableData?.map((dataPart, index) => {
              return (
                  <TableRow
              
                    key={index}
                    className={
                          dataPart.Action === "Bought" ||
                          dataPart.Action === "Sold"
                          ? classes.dataTableBodyBoughtAndSold
                          : classes.dataTableCell
                    }
                  >
                    {Object.entries(dataPart).map(([key, value], index) => {
                      if (key !== "client_user_id" && key !== "trade id" && key !== "highlight_row") {
                        return (
                          <TableCell
                            key={index}
                            className={
                              dataPart["highlight_row"] > new Date().getTime()
                                ? `${classes.dataTableCell} tradedLogs`
                                : classes.dataTableCell
                            }
                                                        style={{
                              textAlign:
                                key === "auction_price" ? "center" : "left",
                              textTransform:
                                key === "user" ||
                                key === "on behalf" ||
                                key === "userName"
                                  ? "capitalize"
                                  : "none",
                            }}
                          >
                            {key === "quantity"
                              ? typeof value === "number"
                                ? value.toLocaleString("en-US")
                                : value !== null
                                ? (+value * 1000000).toLocaleString("en-US")
                                : null
                              : key === "price"
                              ? value !== null
                                ? Number(value).toFixed(3)
                                : ""
                              : value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }
  
  export default SystemLogsView;
  