import React, { useEffect, useState } from "react";
import TradesReportsView from "./TradesReports.view";
import moment from "moment";
import PapaParse from "papaparse";
import Api from "../../../../apis/reportsRequest";
import { useSelector } from "react-redux";

function TradesReports(options) {
  let token = sessionStorage.getItem("token");
  const [reports, setReports] = useState([]);
  const [reportsLoading, setReportsLoading] = useState("false");
  const [chosenDate, setChosenDate] = useState("");
  const [sortedReports, setSortedReports] = useState([]);
  const [isASD, setIsASD] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDelayed, setSearchTermDelayed] = useState("");
  const [sortedField, setSortedField] = useState("Execution Time (UTC)");
  const { viewAsClientUser } = useSelector((state) => state.impersonationSlice);

  // functions
  const onDownloadReportsToCsv = () => {
  
    const csv = PapaParse.unparse(reports);
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(
        blob,
        `trade_Report_Date_${moment(new Date()).format("YYYY-MM-DD")}`
      );
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `Trade_Report_Date_${moment(new Date()).format("YYYY-MM-DD")}`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const dateSetter = (e) => {
    if (e.target.value !== "") {
      let formattedDate = moment(e.target.value).format("DD-MMM-yyyy");
      setChosenDate(formattedDate);
    } else {
      setChosenDate(moment().format("DD-MMM-yyyy"));
    }
  };

  const handleSorting = (field) => {
    setSortedField(field);
    field = field.toLowerCase();
    if (field) {
      const sorted = [...reports].sort((a, b) => {
        if (field === "trade date") {
          const dateA = new Date(a[field]);
          const dateB = new Date(b[field]);

          return isASD ? dateA - dateB : dateB - dateA;
        }
        if (field === "execution time (utc)") {
          const timeFormat = "HH:mm:ss"; 
          function parseTimeString(timeString, format) {
            const [hours, minutes, seconds] = timeString.split(":").map(Number);

            const date = new Date();
            date.setUTCHours(hours);
            date.setUTCMinutes(minutes);
            date.setUTCSeconds(seconds);

            return date;
          }
          const timeA = parseTimeString(a[field], timeFormat);
          const timeB = parseTimeString(b[field], timeFormat);

          return isASD === false ? timeA - timeB : timeB - timeA;
        } else {
          return (
            a[field]?.toString().localeCompare(b[field]?.toString(), "en", {
              numeric: true,
            }) * (isASD ? 1 : -1)
          );
        }
      });
      setIsASD(!isASD);
      setSortedReports(sorted);
    }
  };

  const getReports = async (chosenDate, searchTerm) => {
    setReportsLoading(true);
    try {
      let reports;
      if (viewAsClientUser) {
        reports = await Api.getReports(
          token,
          chosenDate,
          searchTerm,
          viewAsClientUser.clientUserId
        );
      } else {
        reports = await Api.getReports(token, chosenDate, searchTerm);
      }
      setReports(reports.data);
      setSortedReports(reports.data);
      setReportsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setSearchTermDelayed(searchTerm);
    }, 300);

    return () => clearTimeout(delay);
  }, [searchTerm]);

  useEffect(() => {
    getReports(chosenDate, searchTermDelayed);
  }, [chosenDate, searchTermDelayed, viewAsClientUser]);

  return (
    <TradesReportsView
      setChosenDate={setChosenDate}
      reports={sortedReports}
      onDownloadReportsToCsv={onDownloadReportsToCsv}
      dateSetter={dateSetter}
      handleSorting={handleSorting}
      searchTerm={searchTerm}
      setSearchTerm={(newTerm) => setSearchTerm(newTerm)}
      reportsLoading={reportsLoading}
      sortedField={{ fieldName: sortedField, asd: isASD }}
    />
  );
}

export default TradesReports;
