import React from "react";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "412px !important",
    height: "260px !important",

    color: "#FFFFFF !important",
    backgroundColor: "#2D2D2D !important",
  },
  header: {
    textAlign: "center",
    color: "#54A9FF",
    font: "normal normal normal 16px/20px Inter",
  },

  cancelButton: {
    width: "147px",
    height: "32px",
    font: "Inter",
    fontSize: "1.166rem",
    textTransform: "capitalize",
    background: "#494C55  0% 0% no-repeat padding-box",
    borderRadius: "4px",
    color: "#000000",
    opacity: 1,
    "&:Hover": {
      backgroundColor: "#9ACCFF",
    },
  },
  confirmButton: {
    width: "147px",
    height: "32px",
    font: "Inter",
    fontSize: "1.166rem",
    textTransform: "capitalize",
    background: "#54A9FF   0% 0% no-repeat padding-box",
    borderRadius: "4px",
    color: "#000000",
    opacity: 1,
    "&:Hover": {
      backgroundColor: "#9ACCFF",
    },
  },
}));
