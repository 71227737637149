import React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { ReactComponent as MakorIcon } from "../../../../assets/icons/makor-big.svg";
import { ReactComponent as Phone } from "../../../../assets/icons/phone.svg";
import { ReactComponent as Email } from "../../../../assets/icons/email.svg";
import { ReactComponent as Fa } from "../../../../assets/icons/2fa_shild.svg";
import { useStyles } from "./AuthSelector.styles";
import OTPInput from "react-otp-input";

const AuthSelectorView = (props) => {
  const { sendAuthCode, sixDigits, setSixDigits, handleOnClickVerifyCode } =
    props;
  const classes = useStyles();

  const { userPhone, userEmail, chosenWay } = useSelector(
    (state) => state.authSlice
  );
  const authOptions = ["email", "phone"];

  const renderAuthChosen = () => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Paper
          className={classes.choseWayContainer}
          elevation={0}
          style={{ height: 400 }}
        >
          <Grid container alignItems="center" direction="column">
            <Grid item style={{ paddingBlock: "2px 40px" }}>
              <MakorIcon />
            </Grid>
            <Grid item style={{ color: "#ffffff" }}>
              <Typography style={{ fontSize: "1.667rem" }}>
                Authentication
              </Typography>
            </Grid>
            <Grid item xs={12} container alignItems="center" direction="column">
              <Grid
                item
                container
                justifyContent="flex-end"
                style={{ marginTop: 24, color: "#ffffff" }}
              >
                <Grid item xs={10}>
                  <Typography style={{ fontSize: "1.167rem" }}>
                    Select your preferred way
                  </Typography>
                </Grid>
              </Grid>
              {authOptions.map((option) => {
                return (
                  <Button
                    key={option}
                    style={{ marginTop: 16 }}
                    className={classes.signInToggleBtn}
                    disabled={
                      option === "email"
                        ? false
                        : userPhone !== null && userPhone !== ""
                        ? false
                        : true
                    }
                    onClick={() => {
                      sendAuthCode(option);
                    }}
                  >
                    <Grid
                      container
                      xs={11}
                      alignItems="center"
                      direction="row"
                      justifyContent="center"
                    >
                      <Grid item xs={2.5} style={{ paddingRight: 12 }}>
                        {option === "phone" ? <Phone /> : <Email />}
                      </Grid>
                      <Grid
                        item
                        container
                        xs={9}
                        direction="column"
                        justifyContent="flex-start"
                      >
                        <Grid item style={{ paddingBottom: "8px" }}>
                          <Typography
                            style={{
                              textAlign: "left",
                              textTransform: "capitalize",
                              textSize: "1.167rem",
                            }}
                          >{`${`via ${
                            option === "phone" ? "SMS" : option
                          }`}`}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            style={{
                              textAlign: "left",
                              color: "#6C6E73",
                              textSize: "1.167rem",
                            }}
                          >
                            {option === "email"
                              ? userEmail
                              : userPhone !== null && userPhone !== ""
                              ? userPhone
                              : "not available"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Button>
                );
              })}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  // ! after user chose way
  const renderChosenWayAuthCodeEnter = () => {
    return (
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ height: "100vh" }}
      >
        <Paper
          className={classes.otpContainer}
          elevation={0}
          style={{ height: 350 }}
        >
          <Grid container alignItems="center" direction="column">
            <Grid item>
              <MakorIcon />
            </Grid>
            <Grid item container alignItems="center" direction="column">
              <Grid item xs={12} style={{ marginTop: 24, color: "#ffffff" }}>
                <Typography style={{ fontSize: "1.333rem" }}>
                  {chosenWay === "phone"
                    ? `SMS sent with a passcode to`
                    : chosenWay === "email"
                    ? `Email sent with a passcode to`
                    : null}
                </Typography>
                <br />
                <Typography style={{ fontSize: "1.333rem", textAlign : "center" ,marginTop : -10}}>
                  {chosenWay === "phone"
                    ? userPhone
                    : chosenWay === "email"
                    ? userEmail
                    : null}
                </Typography>
              </Grid>
              <Grid item style={{ marginTop: 24, color: "#ffffff" }}>
                <Typography style={{ fontSize: "1.167rem", color: "#9CA1A7" }}>
                  Enter the code below to continue
                </Typography>
              </Grid>
              <Grid item style={{ paddingBlock: "20px" }}>
                <form
                  onKeyDown={(e) =>
                    e.key === "Enter"
                      ? handleOnClickVerifyCode(sixDigits)
                      : null
                  }
                >
                  <OTPInput
                    onFocus={(e) => e.target.select}
                    shouldAutoFocus
                    value={sixDigits}
                    onChange={(e) => setSixDigits(e)}
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => {
                      return <input {...props} />;
                    }}
                    inputStyle={{
                      fontFamily: "monospace",
                      borderRadius: "3px",
                      marginInline: "8px",
                      width: 40,
                      height: 56,
                      fontSize: 30,
                      boxSizing: "border-box",
                      marginLeft: 0,
                      color: "#ffffff",
                      border: `1px solid #414648`,
                      backgroundColor: "#262626",
                      marginTop: "10px",
                      ":focus": {
                        border: "1px solid black",
                      },
                    }}
                  />
                </form>
              </Grid>
            </Grid>
            <Grid item style={{ paddingTop: "8px" }}>
              <Button
                disabled={sixDigits.length < 6 ? true : false}
                className={classes.verifyButton}
                onClick={() => {
                  handleOnClickVerifyCode(sixDigits);
                }}
              >
                Verify Code
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );
  };

  return chosenWay === "" ? renderAuthChosen() : renderChosenWayAuthCodeEnter();
};
export default AuthSelectorView;
