import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "18px !important",
    textAlign: "left",
    // font: "normal normal normal 24px/29px Inter",
    fontSize: "2.1253rem !important",
    letterSpacing: "0px",
    color: "#FFFFFF",
    opacity: "1",
  },
  downloadButton: {
    width: "32px",
    height: "32px",
    background: "#24414B 0% 0% no-repeat padding-box !important",
    border: "1px solid #54A9FF !important",
    borderRadius: "4px",
    opacity: 1,
  },
  datePicker: {
    // Your custom styles here
    "& .MuiInputBase-root": {
      backgroundColor: "white", // Change the background color
    },
    "& .MuiInputBase-input": {
      fontSize: "1.125rem", // Change the font size
    },
    "& .MuiPickersDay-daySelected": {
      // Styles for the selected day
      backgroundColor: "green",
      color: "white",
    },
  },
  date: {
    textAlign: "left",
    font: "normal normal normal 1.125rem Inter",
    letterSpacing: "0px",
    color: "#686B76",
    opacity: 1,
    color: "white",
    border: "1px solid #686B76",
    borderRadius: "4px",
    opacity: 1,
    width: "231px",
    height: "35px",
    marginRight: "10px",
  },
  mainReportsContainer: {
    "@media (min-width: 901px)": {
      width: "calc(100vw - 30px)",
    },
    "@media (max-width: 901px)": {
      padding: "0px 10px",
      width: "calc(100vw - 6px)",
    },
  },
  downloadButton: {
    width: "32px",
    height: "32px",
    background: "#24414B 0% 0% no-repeat padding-box !important",
    border: "1px solid #54A9FF !important",
    borderRadius: "4px",
    opacity: 1,
  },
  datePicker: {
    // Your custom styles here
    "& .MuiInputBase-root": {
      backgroundColor: "white", // Change the background color
    },
    "& .MuiInputBase-input": {
      fontSize: "1.125rem", // Change the font size
    },
    "& .MuiPickersDay-daySelected": {
      // Styles for the selected day
      backgroundColor: "green",
      color: "white",
    },
  },
  date: {
    textAlign: "left",
    font: "normal normal normal 1.125rem Inter",
    letterSpacing: "0px",
    color: "#686B76",
    opacity: 1,
    color: "white",
    border: "1px solid #686B76",
    borderRadius: "4px",
    opacity: 1,
    width: "231px",
    height: "35px",
    marginRight: "10px",
  },
}));
