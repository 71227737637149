import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AuthSelectorView from "./AuthSelector.view";
import Api from "../../../../apis/userRequest";
import ApiLogs from "../../../../apis/logRequest";
import ApiUsers from "../../../../apis/userRequest"
import * as authSlice from "../../../../store/slice/authSlice";
import * as snackBarSlice from "../../../../store/slice/snackbarSlice";
import * as userSlice from "../../../../store/slice/userSlice";
import { Navigate, useNavigate } from "react-router";
import { connectWS } from "../../../../services/ws/websocket";


const AuthSelector = (props) => {
  const dispatch = useDispatch();
  const { token, chosenWay } = useSelector((state) => state.authSlice);
  const [sixDigits, setSixDigits] = useState("");
  const navigate = useNavigate();

  const sendAuthCode = async (chosenWay) => {
    
  
    try {
      let result;
      if (chosenWay !== "2fa") {
        result = await Api.authRequest({ chosenWay }, token);
      }
      dispatch(authSlice.setChosenWay(chosenWay));
      dispatch(
        snackBarSlice.setSnackBar({
          type: "success",
          message: result.data.message,
          timeout: 3000,
        })
      );
    } catch (error) {
      dispatch(
        snackBarSlice.setSnackBar({
          type: "error",
          message: "error sending authentication",
          timeout: 3000,
        })
      );
    }
  };




  const handleOnClickVerifyCode = async () => {
    const authTypeRequest = "login";
    try {
      let response;
      if (chosenWay === "2fa") {
        response = await Api.qrCodePreAuthRequest({ sixDigits }, token);
      } else {
        response = await Api.sixDigitAuthCheck(
          { sixDigits, authTypeRequest },
          token
        );
      }
      if (response.data.token !== undefined && response.data.token !== null) {
        let token = "Bearer " + response.data.token
        sessionStorage.setItem("token", "Bearer " + response.data.token);
        sessionStorage.setItem("userData", JSON.stringify(response.data));
        connectWS(response.data.token);
        navigate("/workspace");
       

        let logDetails = {
          type:"Logged In",
          clientUserId:response.data.clientUserId
        }
        apiPreRequestPermission(token)
        if(response.data.type !== "trader"){
          await ApiLogs.sendLog("Bearer " + response.data.token,logDetails)
        }
       
      }
    } catch (error) {
      console.log(error);
      dispatch(
        snackBarSlice.setSnackBar({
          type: "error",
          message: error.response.data.message,
          timeout: 3000,
        })
      );
    }
  };
  const apiPreRequestPermission = async (token) => {
    try {
      const result = await ApiUsers.pathAuthRequest(token);
      dispatch(userSlice.updateUserDetails(result.data));
    } catch (error) {
      if (error.response.data.status === 498) {
        dispatch(userSlice.clearLoginState());
        navigate("/");
      }
    }
  };
  useEffect(() => {
    return () => {
      dispatch(authSlice.clearUserData());
    };
  }, []);

  return (
    <AuthSelectorView
      handleOnClickVerifyCode={handleOnClickVerifyCode}
      sendAuthCode={sendAuthCode}
      sixDigits={sixDigits}
      setSixDigits={setSixDigits}
    />
  );
};
export default AuthSelector;
