import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import ApiRegion from "../../../../apis/regionRequest";
import ApiClient from "../../../../apis/clientRequest";
import ApiUser from "../../../../apis/userRequest";
import { useNavigate } from "react-router-dom";
import StaticTabsView from "./StaticTabs.view";
import * as regionSlice from "../../../../store/slice/regionSlice"
import * as userSlice from "../../../../store/slice/userSlice"
import { useSelector,useDispatch } from "react-redux";
import ApiUsers from "../../../../apis/userRequest"


const StaticTabs = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  let token = sessionStorage.getItem("token");
  
const apiGetRegions =  async () => {
  try {
    const response = await ApiRegion.getAll(token)
    let regionSorted = response.data?.sort((a, b) => a.name.localeCompare(b.name))
    dispatch(regionSlice.updateRegionsList(regionSorted))
  } catch (error) {
    console.log(error)
  }
}

const apiGetTraders =  async () => {
  try {
    const response = await ApiUser.getAllTraders(token)

    dispatch(userSlice.updateTraders(response.data))
  } catch (error) {
    console.log(error)
  }
}

const apiGetClients =  async () => {
  try {
    const response = await ApiClient.getAllClients(token)
    dispatch(userSlice.updateClients(response.data))
  } catch (error) {
    console.log(error)
  }
}
const apiPreRequestPermission = async () => {
  try {
    const result = await ApiUsers.pathAuthRequest(token);
    dispatch(userSlice.updateUserDetails(result.data));
  } catch (error) {
    if (error.response.data.status === 498) {
      dispatch(userSlice.clearLoginState());
      navigate("/");
    }
  }
};
  useEffect(() => {
    apiGetRegions()
    apiGetTraders()
    apiGetClients()
    apiPreRequestPermission()
  }, []);

  return (
    <Grid>
      <StaticTabsView />
    </Grid>
  );
};

export default StaticTabs;
