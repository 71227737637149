import React, { useEffect, useState } from "react";
import NewAuctionDialogView from "./NewAuctionDialog.view";
import * as snackBarSlice from "../../../../../store/slice/snackbarSlice";
import { useDispatch, useSelector } from "react-redux";
import ApiBonds from "../../../../../apis/bondRequest";
import axios from "axios";
import Papa from "papaparse";

const NewAuctionDialog = (props) => {
  const dispatch = useDispatch();
  const { dialogOpen, closeModal, apiAddAuction, tableData, setTableData } =
    props;
  const token = sessionStorage.getItem("token");
  const [isBlind, setIsBlind] = useState(true);
  const [bonds, setBonds] = useState();
  const [selectedRegionValue, setSelectedRegionValue] = useState("");
  const [auctionTime, setAuctionTime] = useState("05:00");
  const regions = useSelector((state) => state.regionSlice.regions);

  const apiGetAllBonds = async () => {
    try {
      let result = await ApiBonds.getAll(token);
      setBonds(result.data);
    } catch (error) {
      dispatch(
        snackBarSlice.setSnackBar({
          type: "error",
          message: error.response.data,
          timeout: 3000,
        })
      );
    }
  };

  const onDeleteDuplicatedIsin = (bondId) => {
    const filteredData = tableData.filter(
      (obj) => !(obj.bondId === bondId && obj.duplicate === true)
    );

    setTableData(filteredData);
  };
  const handleInputKeyDown = async (e, row) => {
    const [regionId] = regions.filter(
      (key) => key.name === selectedRegionValue
    );
    if (e.key === "Enter") {
      if (row.input.length === 12) {
        let response = await axios.get(
          `${process.env.REACT_APP_ISIN_PRICER}${row.input}`
        );

        const updatedRow = {
          ...row,
          isin: response.data.isin,
          bond_name: response.data.name,
          currency: response.data.currency,
          last_price: response.data.last_price,
          is_active: "1",
          region_id: regionId.id,
          new: true,
        };
        const updatedRows = tableData.map((r) =>
          r.bondId === row.bondId ? updatedRow : r
        );

        setTableData(updatedRows);
      } else {
        dispatch(
          snackBarSlice.setSnackBar({
            type: "error",
            message: `ISIN must contain 12 characters. currently contain ${row.input.length}`,
            timeout: 3000,
          })
        );
      }
    }
  };

  const handleInputChange = (e, row) => {
    const updatedRow = { ...row, input: e.target.value };
    const updatedRows = tableData.map((r) =>
      r.id === row.id ? updatedRow : r
    );
    setTableData(updatedRows);
  };

  const getBondData = async (bond) => {
    try {
      // const updatedRow = {
      //   bondId: bond.bondId,
      //   isin: bond.isin,
      //   bond_name: "WALLOO 0.78 12/20/24",
      //   bond_price: bond.bond_price,
      //   currency: "USD",
      //   is_active: "1",
      //   last_price: "97.545",
      //   region_id: bond.regionId,
      //   region: bond.region,
      //   maturity: "2052-08-15",
      //   duplicate: bond.duplicate,
      // };
      let response = await axios.get(
        `${process.env.REACT_APP_ISIN_PRICER}${bond.isin}`
      );
      const updatedRow = {
        bondId: bond.bondId,
        isin: response.data.isin,
        bond_name: response.data.name,
        bond_price: bond.bond_price,
        currency: response.data.currency,
        is_active: "1",
        last_price: response.data.last_price,
        region_id: bond.regionId,
        region: bond.region,
        maturity: response.data.maturity,
        duplicate: bond.duplicate,
      };
      setTableData((prevState) => [...prevState, updatedRow]);
    } catch (error) {
      console.log(error);
    }
  };

  const showMissingIsinError = (isin) => {
    dispatch(
      snackBarSlice.setSnackBar({
        type: "warning",
        message: `Missing ISIN in the system - ${isin}`,
        timeout: 3000,
        toastId: `missing_isin_${isin}`,
      })
    );
  };

  const validateIsinValues = (uploadedIsins) => {
    const existBonds = uploadedIsins
      .filter((isin) => bonds?.map((bond) => bond.isin).includes(isin.isin))
      .map((isin) => {
        let bond = bonds.find((bond) => bond.isin === isin.isin);
        bond = { ...bond, bond_price: isin.price };
        return bond;
      });
    const notExistBonds = uploadedIsins.filter(
      (isin) => !bonds?.map((bond) => bond.isin).includes(isin.isin)
    );
    if (notExistBonds.length > 0) {
      let isinToCopy = "";
      // notExistBonds.forEach((isin, index) => {
      //   isinToCopy = isinToCopy.concat(isin.isin + "\n");
      //   setTimeout(() => {
      //     showMissingIsinError(isin.isin);
      //   }, 1000);
      //   if (index === notExistBonds.length - 1) {
      //     navigator.clipboard.writeText(isinToCopy);
      //     setTimeout(() => {
      // dispatch(
      //   snackBarSlice.setSnackBar({
      //     type: "success",
      //     message: `Missing isin's copied to clipboard`,
      //     timeout: 3000,
      //     position: "bottom-right",
      //   })
      // );
      //     }, 2000);
      //   }
      // });
      setTimeout(() => {
        dispatch(
          snackBarSlice.setSnackBar({
            type: "warning",
            message: `Missing ISIN in the system!`,
            timeout: 3000,
            position: "top-right",
          })
        );
      }, 1000);
      setTimeout(() => {
        dispatch(
          snackBarSlice.setSnackBar({
            type: "success",
            message: `Missing isin's copied to clipboard`,
            timeout: 3000,
            position: "bottom-right",
          })
        );
      }, 2000);
    }
    let hasDuplicates = false;
    const duplicates = existBonds.map((obj, index, array) => {
      const isDuplicate = array
        .slice(0, index)
        .some((item) => item.isin === obj.isin);
      if (isDuplicate) {
        hasDuplicates = true;
        return { ...obj, duplicate: true };
      }
      return obj;
    });
    if (hasDuplicates)
      dispatch(
        snackBarSlice.setSnackBar({
          type: "warning",
          message: `Duplicated ISIN FOUND`,
          timeout: 3000,
          position: "top-right",
        })
      );
    duplicates.forEach((bond) => {
      if (bond.isin.length === 12) {
              //   const today = new Date();
      //   const start = new Date(today.getFullYear(), today.getMonth(), today.getDate()); // Start date is today
      //   const end = new Date(today.getFullYear() + 10, 11, 31); // End date is 10 years from today
      
      //   const randomDate = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
      
      //   const year = randomDate.getFullYear();
      //   const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      //   const day = String(randomDate.getDate()).padStart(2, '0');
      //  let formattedDate =  `${year}-${month}-${day}`
      //   getBondData(bond,formattedDate);
        getBondData(bond);
      } else {
        dispatch(
          snackBarSlice.setSnackBar({
            type: "error",
            message: `ISIN must contain 12 characters`,
            timeout: 3000,
          })
        );
      }
    });
  };

  const handleButtonClick = () => {
    document.getElementById("fileInput").click();
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (results) => {
        validateIsinValues(results.data);
      },
    });
  };

  const handleIncrement = () => {
    if (auctionTime === "20:00") return;
    const [minutes, seconds] = auctionTime.split(":").map(Number);
    let newMinutes = minutes + 1;
    let newSeconds = seconds;
    setAuctionTime(
      `${newMinutes.toString().padStart(2, "0")}:${newSeconds
        .toString()
        .padStart(2, "0")}`
    );
  };

  const handleDecrement = () => {
    if (auctionTime === "05:00") return;
    const [minutes, seconds] = auctionTime.split(":").map(Number);
    let newMinutes = minutes - 1;
    let newSeconds = seconds;
    setAuctionTime(
      `${newMinutes.toString().padStart(2, "0")}:${newSeconds
        .toString()
        .padStart(2, "0")}`
    );
  };

  const handleBondPriceEnter = (e, row) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, "");
    let match = inputValue.match(/\d+(\.\d{0,3})?/);
    inputValue = match ? match[0] : "";
    const updatedRow = { ...row, bond_price: inputValue };
    const updatedRows = tableData.map((r) =>
      r.bondId === row.bondId ? updatedRow : r
    );
    setTableData(updatedRows);
  };

  const arrangeBondAndSend = async (startInFive) => {
    let auctionObject = {
      is_blind: isBlind,
      start_in_five: startInFive,
      bonds: tableData,
      auction_time: parseInt(auctionTime.split(":")[0], 10),
    };

    if (tableData.length > 0) {
      await apiAddAuction(auctionObject);
    } else {
      dispatch(
        snackBarSlice.setSnackBar({
          type: "error",
          message: `Please add auctions`,
          timeout: 3000,
        })
      );
    }
  };

  useEffect(() => {
    apiGetAllBonds();
  }, []);

  return (
    <NewAuctionDialogView
      tableData={tableData}
      closeModal={closeModal}
      dialogOpen={dialogOpen}
      isBlind={isBlind}
      setIsBlind={setIsBlind}
      handleInputChange={handleInputChange}
      handleInputKeyDown={handleInputKeyDown}
      handleFileUpload={handleFileUpload}
      handleButtonClick={handleButtonClick}
      auctionTime={auctionTime}
      handleDecrement={handleDecrement}
      handleIncrement={handleIncrement}
      handleBondPriceEnter={handleBondPriceEnter}
      arrangeBondAndSend={arrangeBondAndSend}
      onDeleteDuplicatedIsin={onDeleteDuplicatedIsin}
    />
  );
};

export default NewAuctionDialog;
