import React from "react";
import {
  Grid,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "./ClientUserGreet.style";

const ClientUserGreetView = () => {
  const classes = useStyles()
  const {name , clientName} = useSelector((state) => state.userSlice);
  return (
    <div style={{ position: "fixed", top: 0, right: 3 }}>
      <Grid container style={{ width: "fit-content" }}>
        <Grid item container justifyContent="flex-end" alignItems="center">
          <Grid item>
            <Typography className={classes.clientGreet}>
              {`${name} | ${clientName}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default ClientUserGreetView;
