import React, { useEffect, useState, useRef } from "react";
import WorkspaceView from "./Workspace.view";
import ApiAuction from "../../../apis/auctionRequest";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NewAuctionDialog from "./Dialogs/NewAuctionDialog/NewAuctionDialog";
import EditAuctionDialog from "./Dialogs/EditAuctionDialog/EditAuctionDialog";

import { ws, connectWS, sendEvent } from "../../../services/ws/websocket";
import * as userSlice from "../../../store/slice/userSlice";
import ApiUsers from "../../../apis/userRequest";
import ApiLogs from "../../../apis/logRequest";
import * as regionSlice from "../../../store/slice/regionSlice";
import soundEffect from "../../../assets/my-sounds/soundEffect.mp3";
import * as snackBarSlice from "../../../store/slice/snackbarSlice";
import moment from "moment";

const Workspace = () => {
  const audio = new Audio();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const [auctions, setAuctions] = useState([]);
  const [tableData, setTableData] = useState([]);
  // ! logs for traders
  const [logs, setLogs] = useState([]);
  const [sortedLogs, setSortedLogs] = useState([]);
  // ! logs for client users
  const [userLogs, setUserLogs] = useState([]);
  const [sortedUserLogs, setUserSortedLogs] = useState([]);
  const [isASD, setIsASD] = useState(false);
  const [sortedField, setSortedField] = useState("Date Time (UTC)");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDelayed, setSearchTermDelayed] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [auctionEnded, setAuctionEnded] = useState(false);
  const [userEnteredData, setUserEnteredData] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [auctionsTransaction, setAuctionsTransaction] = useState([]);
  const auctionsRef = useRef(auctions);
  const { regions } = useSelector((state) => state.regionSlice);
  const { permission, clientUserId, privileges } = useSelector(
    (state) => state.userSlice
  );
  const { viewAsClientUser, impersonationCreated, impersonationOn } =
    useSelector((state) => state.impersonationSlice);
  const [editAuctiondialogOpen, setEditAuctionDialogOpen] = useState(false);
  const [editAuctionId, setEditAuctionId] = useState("");
  const [editAuctionTableData, setEditAuctionTableData] = useState([]);
  const [buySellInfo, setBuySellInfo] = useState("");
  let userInfo = JSON.parse(sessionStorage.getItem("userData"));
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const { clientUsers } = useSelector((state) => state.userSlice);
  const [isBlurred, setIsBlurred] = useState(false);
  const [showActivity, setShowActivity] = useState(true);
  
  const getValidBuySizeDetailsFromAllAuctions = (data) => {
    const validAuctionIds = data
      .filter((auction) => {
        const hasValidItems = auction.items.some((item) => {
          const sellSize = item.sell_size;
          const buySize = item.buy_size;

          return (
            (sellSize !== "0" &&
              sellSize !== 0 &&
              sellSize !== "" &&
              sellSize !== undefined &&
              sellSize !== null &&
              item.confirmedSell === true) ||
            (buySize !== "0" &&
              buySize !== 0 &&
              buySize !== "" &&
              buySize !== undefined &&
              buySize !== null &&
              item.confirmedBuy === true)
          );
        });

        return hasValidItems;
      })
      .map((auction) => auction.auction_id);

    return validAuctionIds;
  };

  const apiGetAuctions = async () => {
    let impersonationClientUserId = null || viewAsClientUser?.clientUserId;
    try {
      const response = await ApiAuction.getAll(
        token,
        impersonationClientUserId
      );
    } catch (error) {
      console.log(error, "response.data");
    }
  };

  const apiPreRequestPermission = async () => {
    try {
      const result = await ApiUsers.pathAuthRequest(token);
      dispatch(userSlice.updateUserDetails(result.data));
      return result.data.permission;
    } catch (error) {
      if (error.response.data.status === 498) {
        dispatch(userSlice.clearLoginState());
        navigate("/");
      }
    }
  };

  const openNewAuctionDialog = () => {
    setDialogOpen(true);
  };

  const closeModal = () => {
    setDialogOpen(false);
    setTableData([]);
  };

  const apiAddAuction = async (auctionData) => {
    try {
      await ApiAuction.addAuction(token, auctionData);
      setDialogOpen(false);

      apiGetAuctions();

      setTableData([]);
    } catch (error) {
      console.log(error);
    }
  };

  const apiUpdateAuction = async (auctionData) => {
    try {
      await ApiAuction.updateAuction(token, auctionData);
      apiGetAuctions();
      setEditAuctionDialogOpen(false);
      setEditAuctionTableData([]);
      apiGetUserLogs();
    } catch (error) {
      console.log(error);
    }
  };

  const closeEditAuctionModal = () => {
    setEditAuctionDialogOpen(false);
    setEditAuctionId("");
    setEditAuctionTableData([]);
  };

  const openEditAuctionDialog = (auctionId) => {
    setEditAuctionDialogOpen(true);
    setEditAuctionId(auctionId);
  };

  const apiGetUserLogs = async (searchTerm) => {
    if (viewAsClientUser === null && clientUserId === "") {
      try {
        const response = await ApiLogs.getWorkSpaceLogs(token, searchTerm);

        if (searchTerm !== "") {
          // setLogs(response.data);
          // setSortedLogs(response.data);
        }
        // setLogs(response.data);
        // setSortedLogs(response.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const apiGetClientUserSystemLog = async (searchTerm) => {
    try {
      let todayDate = moment(new Date()).format("DD-MMM-yyyy");
      let response;
      if (viewAsClientUser !== null) {
        response = await ApiLogs.getActivityWorkSpaceLog(
          token,
          "systemLog",
          todayDate,
          searchTerm,
          viewAsClientUser.clientUserId
        );
      } else {
        response = await ApiLogs.getActivityWorkSpaceLog(
          token,
          "systemLog",
          todayDate,
          searchTerm
        );
      }

      // setUserLogs(response.data)
      // setUserSortedLogs(response.data)
    } catch (error) {
      console.log(error);
    }
  };

  const handleSorting = (field) => {
    setSortedField(field);
    field = field.toLowerCase();
    let sorted;
    if (clientUserId === "" && (permission === 1 || permission === "1")) {
      if (field) {
        sorted = [...logs].sort((a, b) => {
          if (field === "date time (utc)") {
            const dateA = new Date(a[field]);
            const dateB = new Date(b[field]);

            return !isASD ? dateA - dateB : dateB - dateA;
          } else if (field === "auction price") {
            const valueA =
              a["auction_price"] === null ? "" : a["auction_price"];
            const valueB =
              b["auction_price"] === null ? "" : b["auction_price"];

            return !isASD
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          } else {
            const valueA = a[field] === null ? "" : a[field];
            const valueB = b[field] === null ? "" : b[field];

            return !isASD
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          }
        });
        setIsASD(!isASD);
        setSortedLogs(sorted);
      }
    } else {
      if (field) {
        sorted = [...userLogs].sort((a, b) => {
          if (field === "date time (utc)") {
            const dateA = new Date(a[field]);
            const dateB = new Date(b[field]);

            return !isASD ? dateA - dateB : dateB - dateA;
          } else if (field === "auction price") {
            const valueA =
              a["auction_price"] === null ? "" : a["auction_price"];
            const valueB =
              b["auction_price"] === null ? "" : b["auction_price"];

            return !isASD
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          } else {
            const valueA = a[field] === null ? "" : a[field];
            const valueB = b[field] === null ? "" : b[field];

            return !isASD
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          }
        });
        setIsASD(!isASD);
        setUserSortedLogs(sorted);
      }
    }
  };

  const cancelAll = async () => {
    const result = auctions.flatMap((auction) =>
      auction.items
        .filter(
          (item) =>
            (item.buy_size &&
              item.buy_size !== "0" &&
              item.buy_size !== "" &&
              item.buy_size !== null &&
              item.buy_size !== undefined) ||
            (item.sell_size &&
              item.sell_size !== "0" &&
              item.sell_size !== "" &&
              item.sell_size !== null &&
              item.sell_size !== undefined)
        )
        .map((item) => ({
          auction_id: auction.auction_id,
          auctionStaticId: item.auctionStaticId,
          currency: item.currency,
          bondName: item.bond_name,
          buySize: item.buy_size === "0" ? null : item.buy_size,
          sellSize: item.sell_size === "0" ? null : item.sell_size,
          auctionPrice: item.auction_price,
        }))
    );

    setBuySellInfo({
      agreement: "cancel all order",
      price: "0",
      auctionStaticId: "",
      bondName: "",
      cancelAll: true,
      result,
    });

    setConfirmDialogOpen(true);
  };

  const getValidBuySizeDetails = (data) => {
    const validAuctionIds = data
      .filter((auction) => {
        const hasValidItems = auction.items.some((item) => {
          const sellSize = item.sell_size;
          const buySize = item.buy_size;

          return (
            (sellSize !== "0" &&
              sellSize !== 0 &&
              sellSize !== "" &&
              sellSize !== undefined &&
              sellSize !== null) ||
            (buySize !== "0" &&
              buySize !== 0 &&
              buySize !== "" &&
              buySize !== undefined &&
              buySize !== null)
          );
        });

        return hasValidItems;
      })
      .map((auction) => {
        return {
          auctionId: auction.auction_id,
        };
      });

    return validAuctionIds;
  };

  const getValidBuySizeDetailsFromAuction = (auctionObject, bidStatus) => {
    if (bidStatus === "buy") {
      const filteredArray = auctionObject.filter(
        (item) =>
          item.buy_size !== "0" &&
          item.buy_size !== 0 &&
          item.buy_size !== "" &&
          item.buy_size !== undefined &&
          item.buy_size !== null &&
          item.confirmedBuy === true
      );
      const transformedArray = filteredArray.map((item) => ({
        bondName: item.bond_name,
        currency: item.currency,
        buySize: item.buy_size,
        auctionPrice: item.auction_price,
        auctionStaticId: item.auctionStaticId,
      }));
      return transformedArray;
    } else if (bidStatus === "sell") {
      const filteredArray = auctionObject.filter(
        (item) =>
          item.sell_size !== "0" &&
          item.sell_size !== 0 &&
          item.sell_size !== "" &&
          item.sell_size !== undefined &&
          item.sell_size !== null &&
          item.confirmedSell === true
      );
      const transformedArray = filteredArray.map((item) => ({
        bondName: item.bond_name,
        currency: item.currency,
        sellSize: item.sell_size,
        auctionPrice: item.auction_price,
        auctionStaticId: item.auctionStaticId,
      }));
      return transformedArray;
    } else if (bidStatus === "cancel all order") {
      const filteredArray = auctionObject.flatMap((auction) =>
        auction.items.filter(
          (item) =>
            (item.sell_size !== "0" &&
              item.sell_size !== 0 &&
              item.sell_size !== "" &&
              item.sell_size !== undefined &&
              item.sell_size !== null &&
              item.confirmedSell === true) ||
            (item.buy_size !== "0" &&
              item.buy_size !== 0 &&
              item.buy_size !== "" &&
              item.buy_size !== undefined &&
              item.buy_size !== null &&
              item.confirmedBuy === true)
        )
      );
      const transformedArray = filteredArray.map((item) => ({
        bondName: item.bond_name,
        currency: item.currency,
        sellSize: item.sell_size === "0" ? null : item.sell_size,
        buySize: item.buy_size === "0" ? null : item.buy_size,
        auctionPrice: item.auction_price,
        auctionStaticId: item.auctionStaticId,
      }));
      return transformedArray;
    } else {
      const filteredArray = auctionObject.filter(
        (item) =>
          (item.sell_size !== "0" &&
            item.sell_size !== 0 &&
            item.sell_size !== "" &&
            item.sell_size !== undefined &&
            item.sell_size !== null &&
            item.confirmedSell === true) ||
          (item.buy_size !== "0" &&
            item.buy_size !== 0 &&
            item.buy_size !== "" &&
            item.buy_size !== undefined &&
            item.buy_size !== null &&
            item.confirmedBuy === true)
      );
      const transformedArray = filteredArray.map((item) => ({
        bondName: item.bond_name,
        currency: item.currency,
        sellSize: item.sell_size === "0" ? null : item.sell_size,
        buySize: item.buy_size === "0" ? null : item.buy_size,
        auctionPrice: item.auction_price,
        auctionStaticId: item.auctionStaticId,
      }));
      return transformedArray;
    }
  };

  const apiRemoveTransaction = async (
    clientUserId,
    viewAsClientUserId,
    clientFullName
  ) => {
    try {
      if (buySellInfo.agreement === "cancel all order") {
        const filterAuctions = getValidBuySizeDetailsFromAuction(
          auctions,
          "cancel all order"
        );
        if (filterAuctions.length === 0) {
          setConfirmDialogOpen(false);
          dispatch(
            snackBarSlice.setSnackBar({
              type: "warning",
              message: `No orders to cancel`,
            })
          );
        } else {
          let logDetails = {
            type: "remove all bid",
            clientUserId: clientUserId,
            agreement: buySellInfo.agreement,
            viewAsClientUserId: viewAsClientUserId,
            auctionToRemove: filterAuctions,
          };
          const auctionIdToRemove =
            getValidBuySizeDetailsFromAllAuctions(auctions);

          await ApiAuction.removeAllTransaction(
            token,
            auctionIdToRemove,
            clientUserId,
            filterAuctions
          );

          setConfirmDialogOpen(false);
          await ApiLogs.sendLog(token, logDetails);
          const updatedArray = auctions.map((auction) => ({
            ...auction,
            items: auction.items.map((item) => ({
              ...item,
              buy_size: "0",
              sell_size: "0",
            })),
          }));
          setAuctions(updatedArray);
        }
      } else if (buySellInfo.agreement === "cancel buy") {
        const itemsWithAuctionId = auctions.flatMap((auction) =>
          auction.items.filter(
            (item) => item.auction_id === buySellInfo.auctionId
          )
        );
        const filterAuctions = getValidBuySizeDetailsFromAuction(
          itemsWithAuctionId,
          "buy"
        );
        if (filterAuctions.length === 0) {
          setConfirmDialogOpen(false);
          dispatch(
            snackBarSlice.setSnackBar({
              type: "warning",
              message: `No orders to cancel`,
            })
          );
        } else {
          let logDetails = {
            type: "remove bid",
            clientFullName: clientFullName,
            clientUserId: clientUserId,
            agreement: buySellInfo.agreement,
            viewAsClientUserId: viewAsClientUserId,
            auctionToRemove: filterAuctions,
          };
          const auctionStaticIds = filterAuctions?.map(
            (item) => item.auctionStaticId
          );

          await ApiAuction.removeBuyTransaction(
            token,
            buySellInfo.auctionId,
            clientUserId,
            auctionStaticIds
          );

          setConfirmDialogOpen(false);
          await ApiLogs.sendLog(token, logDetails);

          const updatedArray = auctions?.map((auction) => {
            if (auction.auction_id === buySellInfo.auctionId) {
              const updatedItems = auction.items.map((item) => {
                if (auctionStaticIds.includes(item.auctionStaticId)) {
                  return {
                    ...item,
                    buy_size: "0",
                    copy_buy_size: "0",
                  };
                }
                return item;
              });

              return {
                ...auction,
                items: updatedItems,
              };
            }

            return auction;
          });
          setAuctions(updatedArray);
        }
      } else if (buySellInfo.agreement === "cancel sell") {
        const itemsWithAuctionId = auctions.flatMap((auction) =>
          auction.items.filter(
            (item) => item.auction_id === buySellInfo.auctionId
          )
        );
        const filterAuctions = getValidBuySizeDetailsFromAuction(
          itemsWithAuctionId,
          "sell"
        );
        if (filterAuctions.length === 0) {
          setConfirmDialogOpen(false);
          dispatch(
            snackBarSlice.setSnackBar({
              type: "warning",
              message: `No orders to cancel`,
            })
          );
        } else {
          let logDetails = {
            type: "remove bid",
            clientFullName: clientFullName,
            clientUserId: clientUserId,
            agreement: buySellInfo.agreement,
            viewAsClientUserId: viewAsClientUserId,
            auctionToRemove: filterAuctions,
          };
          const auctionStaticIds = filterAuctions?.map(
            (item) => item.auctionStaticId
          );
          await ApiAuction.removeSellTransaction(
            token,
            buySellInfo.auctionId,
            clientUserId,
            auctionStaticIds
          );
          setConfirmDialogOpen(false);
          await ApiLogs.sendLog(token, logDetails);
          const updatedArray = auctions.map((auction) => {
            if (auction.auction_id === buySellInfo.auctionId) {
              const updatedItems = auction.items.map((item) => {
                if (auctionStaticIds.includes(item.auctionStaticId)) {
                  return {
                    ...item,
                    sell_size: "0",
                    copy_sell_size: "0",
                  };
                }
                return item;
              });

              return {
                ...auction,
                items: updatedItems,
              };
            }

            return auction;
          });
          setAuctions(updatedArray);
        }
      } else {
        const itemsWithAuctionId = auctions.flatMap((auction) =>
          auction.items.filter(
            (item) => item.auction_id === buySellInfo.auctionId
          )
        );
        const result = getValidBuySizeDetailsFromAuction(
          itemsWithAuctionId,
          "buy&sell"
        );
        if (result.length === 0) {
          setConfirmDialogOpen(false);
          dispatch(
            snackBarSlice.setSnackBar({
              type: "warning",
              message: `No orders to cancel`,
            })
          );
        } else {
          let logDetails = {
            type: "remove bid",
            clientFullName: clientFullName,
            clientUserId: clientUserId,
            agreement: buySellInfo.agreement,
            viewAsClientUserId: viewAsClientUserId,
            auctionToRemove: result,
          };
          const auctionStaticIds = result?.map((item) => item.auctionStaticId);
          await ApiAuction.removeAllTransactionOfAuction(
            token,
            buySellInfo.auctionId,
            clientUserId,
            auctionStaticIds
          );
          setConfirmDialogOpen(false);
          await ApiLogs.sendLog(token, logDetails);
          const updatedArray = auctions.map((auction) => {
            if (auction.auction_id === buySellInfo.auctionId) {
              const updatedItems = auction.items.map((item) => ({
                ...item,
                buy_size: "0",
                copy_buy_size: "0",
                sell_size: "0",
                copy_sell_size: "0",
              }));

              return {
                ...auction,
                items: updatedItems,
              };
            }

            return auction;
          });

          setAuctions(updatedArray);
        }
      }

      setBuySellInfo("");
      if (permission === "1" || permission === 1) {
        if (viewAsClientUser === null) {
          apiGetUserLogs();
        } else {
          getClientUserSystemLog();
        }
      } else {
        getClientUserSystemLog();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const findConfirmedStatus = (auctions, targetAuctionStaticId) => {
    for (let auction of auctions) {
      for (let item of auction.items) {
        if (item.auctionStaticId === targetAuctionStaticId) {
          return {
            confirmedBuy: item.confirmedBuy,
            confirmedSell: item.confirmedSell,
            copyBuySize: item.copy_buy_size,
            copySellSize: item.copy_sell_size,
          };
        }
      }
    }
    return null;
  };

  const handleConfirmButtonClick = async (buttonValue) => {
    if (buttonValue === "Cancel") {
      setBuySellInfo({ ...buySellInfo, price: "0" });
      if (buySellInfo.agreement === "buy") {
        const updatedArray = auctions.map((auction) => {
          const updatedItems = auction.items.map((item) => {
            if (item.auctionStaticId === buySellInfo.auctionStaticId) {
              return {
                ...item,
                buy_size: item.copy_buy_size,
              };
            }
            return item;
          });

          return {
            ...auction,
            items: updatedItems,
          };
        });
        setAuctions(updatedArray);
      } else {
        const updatedArray = auctions.map((auction) => {
          const updatedItems = auction.items.map((item) => {
            if (item.auctionStaticId === buySellInfo.auctionStaticId) {
              return {
                ...item,
                sell_size: item.copy_sell_size,
              };
            }
            return item;
          });

          return {
            ...auction,
            items: updatedItems,
          };
        });
        setAuctions(updatedArray);
      }
      setConfirmDialogOpen(false);
      return;
    }
    let newBuySell = null;
    let clientUserId =
      viewAsClientUser === null
        ? userInfo.clientUserId
        : viewAsClientUser.clientUserId;
    let viewAsClientUserId =
      viewAsClientUser !== null
        ? clientUsers?.find(
            (user) => user.clientUserId === viewAsClientUser.clientUserId
          )?.fullName
        : "";
    let clientFullName = clientUsers?.find(
      (user) => user.clientUserId === userInfo.clientUserId
    )?.fullName;

    if (buySellInfo.cancelAll === true) {
      apiRemoveTransaction(clientUserId, viewAsClientUserId, clientFullName);
    } else {
      if (viewAsClientUser !== null) {
        newBuySell = {
          ...buySellInfo,
          viewAsClientUserId: viewAsClientUser.clientUserId,
        };
      }
      try {
        const allAuction = auctions.find(
          (auction) => auction.auction_id === buySellInfo.auctionId
        );
        const auction = allAuction
          ? allAuction.items.filter(
              (item) => item.auctionStaticId === buySellInfo.auctionStaticId
            )
          : [];
        if (buySellInfo.price === "0" || buySellInfo.price === "") {
          if (buySellInfo.agreement === "buy") {
            const updatedArray = auctions.map((auction) => {
              const updatedItems = auction.items.map((item) => {
                if (item.auctionStaticId === buySellInfo.auctionStaticId) {
                  return {
                    ...item,
                    buy_size: "0",
                  };
                }
                return item;
              });

              return {
                ...auction,
                items: updatedItems,
              };
            });
            setAuctions(updatedArray);
          } else {
            const updatedArray = auctions.map((auction) => {
              const updatedItems = auction.items.map((item) => {
                if (item.auctionStaticId === buySellInfo.auctionStaticId) {
                  return {
                    ...item,
                    sell_size: "0",
                  };
                }
                return item;
              });

              return {
                ...auction,
                items: updatedItems,
              };
            });
            setAuctions(updatedArray);
          }
        }
        buySellInfo.clientFullName = clientFullName;
        buySellInfo.clientUserId = clientUserId;
        buySellInfo.viewAsClientUserId = viewAsClientUserId;

        const confirmedStatus = findConfirmedStatus(
          auctions,
          buySellInfo.auctionStaticId
        );
        if (buySellInfo.price === "") {
          if (buySellInfo.agreement === "sell") {
            if (
              confirmedStatus.copySellSize === "0" ||
              confirmedStatus.copySellSize === null ||
              confirmedStatus.copySellSize === undefined
            ) {
              setConfirmDialogOpen(false);
              dispatch(
                snackBarSlice.setSnackBar({
                  type: "warning",
                  message: `No orders to cancel`,
                })
              );
            }
          } else {
            if (
              confirmedStatus.copyBuySize === "0" ||
              confirmedStatus.copyBuySize === null ||
              confirmedStatus.copyBuySize === undefined
            ) {
              setConfirmDialogOpen(false);
              dispatch(
                snackBarSlice.setSnackBar({
                  type: "warning",
                  message: `No orders to cancel`,
                })
              );
            }
          }
        }

        if (auction.length > 0) {
          await ApiAuction.addAuctionTransactionByClientId(
            token,
            newBuySell === null ? buySellInfo : newBuySell
          );

          setConfirmDialogOpen(false);
          setBuySellInfo({ ...buySellInfo, price: "" });

          if (permission === "1" || permission === 1) {
            if (viewAsClientUser === null) {
              apiGetUserLogs();
            } else {
              getClientUserSystemLog();
            }
          } else {
            getClientUserSystemLog();
          }
        } else {
          setConfirmDialogOpen(false);
          return;
          // dispatch(
          //   snackBarSlice.setSnackBar({
          //     type: "warning",
          //     message: `No orders to cancel`,
          //   })
          // );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const closeConfirmModal = () => {
    setConfirmDialogOpen(false);
  };

  const getClientUserSystemLog = async () => {
    let response;
    try {
      let todayDate = moment(new Date()).format("DD-MMM-yyyy");
      if (viewAsClientUser === null) {
        response = await ApiLogs.getActivityWorkSpaceLog(
          token,
          "systemLog",
          todayDate,
          searchTerm
        );
      } else {
        response = await ApiLogs.getActivityWorkSpaceLog(
          token,
          "systemLog",
          todayDate,
          searchTerm,
          viewAsClientUser.clientUserId
        );
      }
      if(response.date !== ""){
        setUserLogs(response.data);
        setUserSortedLogs(response.data)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputBlur = (e, buySize, auctionStaticId, agreement) => {
    if (agreement === "buy") {
      const updatedData = auctions.map((auction) => {
        const updatedItems = auction.items.map((item) => {
          if (item.auctionStaticId === auctionStaticId) {
            if (
              item.copy_buy_size !== undefined &&
              item.copy_buy_size !== null
            ) {
              // setBuySellInfo({...buySellInfo,oldBuyOrSell:"0"})
              return {
                ...item,
                buy_size: item.copy_buy_size,
              };
            } else {
              return {
                ...item,
                buy_size: "0",
              };
            }
          }

          return item;
        });

        return {
          ...auction,
          items: updatedItems,
        };
      });

      setAuctions(updatedData);
    } else {
      const updatedData = auctions.map((auction) => {
        const updatedItems = auction.items.map((item) => {
          if (item.auctionStaticId === auctionStaticId) {
            if (
              item.copy_sell_size !== undefined &&
              item.copy_sell_size !== null
            ) {
              // setBuySellInfo({...buySellInfo,oldBuyOrSell:"0"})
              return {
                ...item,
                sell_size: item.copy_sell_size,
              };
            } else {
              return {
                ...item,
                sell_size: "0",
              };
            }
          }
          return item;
        });

        return {
          ...auction,
          items: updatedItems,
        };
      });

      setAuctions(updatedData);
    }
  };
  
  useEffect(() => {
    apiGetAuctions();
    apiPreRequestPermission();
    if (clientUserId === "") {
      apiGetUserLogs(searchTerm);
    } else {
      apiGetClientUserSystemLog(searchTerm);
    }
  }, []);

  useEffect(() => {
    if (impersonationCreated === true) {
      setAuctions([]);
      apiGetAuctions();
      apiGetClientUserSystemLog(searchTerm);
    } else {
      if (clientUserId === "") {
        apiGetUserLogs();
      }
    }
  }, [viewAsClientUser, impersonationCreated, regions]);

  useEffect(() => {
    const auctionDetails = (message) => {
      switch (message?.data?.type) {
        case "get_all_auction":
          const data = message.data.data;
          if (data !== undefined && data !== null && data.length > 0) {
            const updatedArray = data.map((item) => {
              const correspondingItem = auctions?.reduce(
                (foundItem, arrayItem) => {
                  if (foundItem) return foundItem;
                  return arrayItem.items.find(
                    (auction) =>
                      auction.auctionStaticId === item.auctionStaticId
                  );
                },
                null
              );

              if (correspondingItem) {
                const updatedItem = { ...item };
                if (correspondingItem.buy_size !== undefined) {
                  if (item.tradedTime) {
                    const tradedTime = new Date(item.tradedTime).getTime();
                    const currentTime = new Date().getTime();
                    const timeRemaining = tradedTime - currentTime;
                    if (timeRemaining <= 0) {
                      updatedItem.buy_size = correspondingItem.buy_size;
                      if (correspondingItem.buy_size !== "0") {
                        updatedItem.confirmedBuy =
                          correspondingItem.buy_size !== undefined &&
                          correspondingItem.buy_size !== "" &&
                          correspondingItem.buy_size !== item.buy_size
                            ? false
                            : true;
                      }
                    }
                  } else {
                    if (updatedItem.removeByTrader) {
                      const currentTime = new Date();
                      const soundTime = new Date(updatedItem.removeByTrader).getTime();
                      const timeRemaining = soundTime - currentTime;
                      if (timeRemaining <= 0) {
                        updatedItem.buy_size = correspondingItem.buy_size;
                      } 
                    }else {
                      updatedItem.buy_size = correspondingItem.buy_size;
                      if (correspondingItem.buy_size !== "0") {
                        updatedItem.confirmedBuy =
                          correspondingItem.buy_size !== undefined &&
                          correspondingItem.buy_size !== "" &&
                          correspondingItem.buy_size !== item.buy_size
                            ? false
                            : true;
                      }
                    }
                    // updatedItem.buy_size = correspondingItem.buy_size;
                    // if (correspondingItem.buy_size !== "0") {
                    //   updatedItem.confirmedBuy =
                    //     correspondingItem.buy_size !== undefined &&
                    //     correspondingItem.buy_size !== "" &&
                    //     correspondingItem.buy_size !== item.buy_size
                    //       ? false
                    //       : true;
                    // }
                  }
                }
                if (correspondingItem.sell_size !== undefined) {
                  if (item.tradedTime) {
                    const tradedTime = new Date(item.tradedTime).getTime();
                    const currentTime = new Date().getTime();
                    const timeRemaining = tradedTime - currentTime;
                    if (timeRemaining <= 0) {
                      updatedItem.sell_size = correspondingItem.sell_size;
                      if (correspondingItem.sell_size !== "0") {
                        updatedItem.confirmedSell =
                          correspondingItem.sell_size !== undefined &&
                          correspondingItem.sell_size !== "" &&
                          correspondingItem.sell_size !== item.sell_size
                            ? false
                            : true;
                      }
                    }
                  } else {

                    if (updatedItem.removeByTrader) {
                      const currentTime = new Date();
                      const soundTime = new Date(updatedItem.removeByTrader).getTime();
                      const timeRemaining = soundTime - currentTime;
                      if (timeRemaining <= 0) {
                        updatedItem.sell_size = correspondingItem.sell_size;
                      } 
                    }else {
                      updatedItem.sell_size = correspondingItem.sell_size;
                      if (correspondingItem.sell_size !== "0") {
                        updatedItem.confirmedBuy =
                          correspondingItem.sell_size !== undefined &&
                          correspondingItem.sell_size !== "" &&
                          correspondingItem.sell_size !== item.sell_size
                            ? false
                            : true;
                      }
                    }

                    // updatedItem.sell_size = correspondingItem.sell_size;
                    // if (correspondingItem.sell_size !== "0") {
                    //   updatedItem.confirmedSell =
                    //     correspondingItem.sell_size !== undefined &&
                    //     correspondingItem.sell_size !== "" &&
                    //     correspondingItem.sell_size !== item.sell_size
                    //       ? false
                    //       : true;
                    // }
                  }
                }
                return updatedItem;
              }
              return item;
            });
            const groupedAuctions = {};
            updatedArray.forEach((item) => {
              const auctionId = item?.auction_id;
              if (!groupedAuctions.hasOwnProperty(auctionId)) {
                groupedAuctions[auctionId] = [];
              }
              groupedAuctions[auctionId].push(item);
            });
            const result = Object.entries(groupedAuctions).map(
              ([auction_id, items]) => {
                return {
                  auction_id: Number(auction_id),
                  items: items.map((item, index) => ({ ...item, index })),
                  formattedTimeLeft: items[0].formattedTimeLeft,
                  phrase: items[0].phrase,
                  createdAt: items[0].created_at,
                };
              }
            );
            result.forEach((resultItem) => {
              const matchingAuction = auctions.find(
                (auction) => auction.auction_id === resultItem.auction_id
              );
              if (matchingAuction) {
                resultItem.open = matchingAuction.open;
              } else {
                resultItem.open = true;
              }
            });
            const copyResult = JSON.parse(JSON.stringify(result));

            const currentTime = new Date().getTime();
            copyResult.forEach((resultItem) => {
              resultItem.items.forEach((item) => {
                if (item.visibleTime) {
                  const visibleTime = new Date(item.visibleTime);
                  const timeRemaining = visibleTime - currentTime;
                  if (timeRemaining <= 0) {
                    item.visible = false;
                  } else {
                    setTimeout(() => {
                      item.visible = false;
                    }, timeRemaining);
                  }
                }

                if (item.tradedTime) {
                  const tradedTime = new Date(item.tradedTime).getTime();

                  const timeRemaining = tradedTime - currentTime;

                  if (timeRemaining <= 0) {
                    item.traded = false;
                  } else {
                    setTimeout(() => {
                      item.traded = false;
                    }, timeRemaining);
                  }
                }
                if (item.soundTime) {
                  const soundTime = new Date(item.soundTime).getTime();
                  const timeRemaining = soundTime - currentTime;
                  if (timeRemaining <= 0) {
                    item.sound = false;
                  } else {
                    audio.src = soundEffect;
                    audio.play();
                    setTimeout(() => {
                      item.sound = false;
                    }, timeRemaining);
                  }
                }
              });
            });

            setUserEnteredData(copyResult);

            const sortedArray = copyResult
              .sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt);
              })
              .map((obj) => {
                return {
                  ...obj,
                  items: obj.items
                    .sort((a, b) => {
                      return new Date(a.maturity) - new Date(b.maturity);
                    })
                    .map((item, index) => ({ ...item, index })), // Add the index property here
                };
              });
            auctionsRef.current = sortedArray;

            setAuctions(sortedArray);
          } else {
            setAuctions([]);
          }

          break;
        case "update_regions":
          if (message?.data?.newRegions?.length > 0) {
            if (permission === "1") {
              if (impersonationOn) {
                if (
                  message.data.clientUserId === viewAsClientUser.clientUserId
                ) {
                  dispatch(
                    regionSlice.updateSubscription(message.data.newRegions)
                  );
                }
              }
            } else {
              if (message.data.clientUserId === clientUserId) {
                dispatch(
                  regionSlice.updateSubscription(message.data.newRegions)
                );
              }
            }
          }
          break;
      }
    };
    window.addEventListener("message", auctionDetails);

    return () => {
      window.removeEventListener("message", auctionDetails);
    };
  }, [auctions, regions]);

  useEffect(() => {
    const logDetails = (message) => {
      // console.log(message?.data)
      switch (message?.data?.type) {
        case "update_trader_logs":
          setLogs(message.data.data);
          setSortedLogs(message.data.data);
          break;
        case "update_client_user_logs":
          setUserLogs(message.data.data);
          setUserSortedLogs(message.data.data);
          break;
        default:
          break;
      }
    };
    window.addEventListener("message", logDetails);
    return () => {
      window.removeEventListener("message", logDetails);
    };
  }, [logs,sortedUserLogs]);

  useEffect(() => {
  if (ws !== null && ws.readyState === WebSocket.OPEN && token) {
      sendEvent({
        type: "get_all_auction",
        data: {},
        metaData: {},
        token: token.replace("Bearer ", ""),
      });
    } else {
      connectWS(token.replace("Bearer ", ""));
    }
    return async () => {};
  }, []);

  useEffect(() => {
    const delaySearchSystemLog = setTimeout(() => {
      setSearchTermDelayed(searchTerm);
    }, 300);

    return () => clearTimeout(delaySearchSystemLog);
  }, [searchTerm]);

  useEffect(() => {
    apiPreRequestPermission();
    if (privileges.length > 0) {
      if (privileges[0] === "trader") {
        apiGetUserLogs(searchTermDelayed);
      } else {
        apiGetClientUserSystemLog(searchTermDelayed);
      }
    }
  }, [searchTermDelayed, permission]);

  useEffect(() => {
    auctionsRef.current = auctions;
  }, [auctions]);

  const [isLoading, setIsLoading] = useState(true);
  const [usersOnline, setUsersOnline] = useState([]);

    const handleUsersButtonClick = async () => {
      setIsLoading(true);
      try {
        const response = await ApiUsers.getOnlineUsers(token)
        setUsersOnline(response.data)
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

  
  return (
    <>
      <WorkspaceView
        openNewAuctionDialog={openNewAuctionDialog}
        auctions={auctions}
        setAuctions={setAuctions}
        auctionsTransaction={auctionsTransaction}
        setAuctionsTransaction={setAuctionsTransaction}
        apiGetAuctions={apiGetAuctions}
        // handleRegionSelect={handleRegionSelect}
        selectedRegions={selectedRegions}
        userEnteredData={userEnteredData}
        setUserEnteredData={setUserEnteredData}
        auctionsRef={auctionsRef}
        auctionEnded={auctionEnded}
        setAuctionEnded={setAuctionEnded}
        openEditAuctionDialog={openEditAuctionDialog}
        logs={sortedLogs}
        handleSorting={handleSorting}
        sortedField={{ fieldName: sortedField, asd: isASD }}
        setSearchTerm={(newTerm) => setSearchTerm(newTerm)}
        setLogs={setLogs}
        cancelAll={cancelAll}
        getValidBuySizeDetails={getValidBuySizeDetails}
        setSortedLogs={setSortedLogs}
        searchTerm={searchTerm}
        setUserLogs={setUserLogs}
        setUserSortedLogs={setUserSortedLogs}
        buySellInfo={buySellInfo}
        setBuySellInfo={setBuySellInfo}
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        sortedUserLogs={sortedUserLogs}
        closeConfirmModal={closeConfirmModal}
        handleInputBlur={handleInputBlur}
        handleConfirmButtonClick={handleConfirmButtonClick}
        showActivity={showActivity}
        setShowActivity={setShowActivity}
        handleUsersButtonClick={handleUsersButtonClick}
        isLoading={isLoading}
         usersOnline= {usersOnline}
      />
      <NewAuctionDialog
        apiAddAuction={apiAddAuction}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        closeModal={closeModal}
        tableData={tableData}
        setTableData={setTableData}
      />

      <EditAuctionDialog
        editAuctiondialogOpen={editAuctiondialogOpen}
        setEditAuctionDialogOpen={setEditAuctionDialogOpen}
        closeEditAuctionModal={closeEditAuctionModal}
        editAuctionId={editAuctionId}
        auctions={auctions}
        editAuctionTableData={editAuctionTableData}
        setEditAuctionTableData={setEditAuctionTableData}
        apiUpdateAuction={apiUpdateAuction}
      />
    </>
  );
};

export default Workspace;
//   if (correspondingItem && correspondingItem.buy_size !== undefined) {
//       return {
//           ...item,
//           buy_size: correspondingItem.buy_size,
//           confirmedBuy:correspondingItem.buy_size !== undefined && correspondingItem.buy_size !== "" && correspondingItem.buy_size !== item.buy_size ? false : true
//       };
//   }
//   if (correspondingItem && correspondingItem.sell_size !== undefined) {
//     return {
//         ...item,
//         sell_size: correspondingItem.sell_size,
//         confirmedSell:correspondingItem.sell_size !== undefined && correspondingItem.sell_size !== "" && correspondingItem.sell_size !== item.sell_size ? false : true
//     };
// }
