import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  auctionCollapseGrid: {
    backgroundColor: "#222222",
    borderRadius: "4px",
    padding: "3px",
    marginBottom: "10px",
    // height:"300px"
    "@media (max-width: 830px)": {
      minWidth: "1000px !important",
    },
  },
  timeLeft: {
    color: "white",
    width: "157px",
    height: "32px",
    backgroundColor: "#24414B",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
  endedTimeLeft: {
    width: "157px",
    height: "32px",
    border: "2px solid #FF3939",
    backgroundColor: "#FF393945",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
  timeBeforeStart: {
    width: "157px",
    height: "32px",
    backgroundColor: "#37244B",
    borderRadius: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
  editButton: {
    marginRight: "10px",
    borderRadius: "4px",
    backgroundColor: "#FF9E54",
    "&:Hover": {
      backgroundColor: "#FFBC89",
    },
  },
  disabledButton: {
    opacity: 0.5,
    marginRight: "10px",
    borderRadius: "4px",
    backgroundColor: "#FF9E54",
    "&:Hover": {
      backgroundColor: "#FFBC89",
    },
  },
  bondInfoGrid: {
    // height: "351px",
    width: "317px",
    border: "1px solid #3D3F47",
    // borderRadius: "4px",
    marginRight: "1px",
    height: "fit-content",
  },
  bondTradedGrid: {
    width: "40px",
    border: "1px solid #3D3F47",
    borderRadius: "4px",
    marginRight: "10px",
  },
  headerTableRow: {
    fontSize: "1rem",
    color: "#5a5c62",
    textAlign: "center",
    borderBottom: "none",
    fontWeight: "600",
  },
  inMillions: {
    fontSize: "1.083rem",
    color: "#5a5c62",
    textAlign: "center",
    borderBottom: "none",
    fontWeight: "600",
  },
  cancelBuySellButton: {
    textAlign: "left",
    textDecoration: "underline",
    textTransform: "none",
    fontSize: "1.167rem",
    font: "Inter",
    color: "#FF9E54",
    border: "none",
    "&:hover": {
      border: "none",
      background: "none",
      textDecoration: "underline",
    },
  },
  tooltipHeaderTableRow: {
    fontSize: "1rem",
    backgroundColor: "2d2d2d",
    borderBottom: "none",
    fontWeight: "600",
  },
  dataTableBody: {
    border: "1px solid #222222",
    "& tr:nth-of-type(odd)": {
      backgroundColor: " #222222",
    },
    
  },
  dataTableBodyEven: {
    border: "1px solid #2d2d2d",
    "& tr:nth-of-type(odd)": {
      backgroundColor: "#2d2d2d",
    },
  },
  blindAuctionClass: {
    border: "1px solid #54A9FF",
    "& tr:nth-of-type(odd)": {
      borderTop:"1px solid #54A9FF",
      backgroundColor: "#54A9FF40",
     
    },
    "& tr:nth-of-type(even)": {
      borderTop:"1px solid #54A9FF",
      backgroundColor: "#54A9FF40",
    },
  },
  dataTableCell: {

    fontSize: "13px !important",
    color: "#ffffff !important",
    textAlign: "center !important",
    borderBottom: "none ",
    height: "25px",
  },
  boughtSoldTableCell: {
    fontSize: "13px !important",
    color: "#ffffff !important",
    textAlign: "center !important",
    borderBottom: "none",
    height: "30px",
  },
  activeInterest: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  bondBidAndSellGrid: {
    border: "1px solid #3D3F47",
    // borderRadius: "4px",
  },
  auctionPriceTextField: {
    backgroundColor: "#282828",
    "& .MuiInputBase-input": {
      // color: "#FFFFFF",
      fontSize: "14px",
      // width: "50px",
      height: "0px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#54A9FF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#494C55",
      },
      "&:hover fieldset": {
        borderColor: "#494C55",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#54A9FF",
      },
    },
  },
  auctionPriceTextFieldDisabled: {
    "&.Mui-disabled": {
      "& .MuiInputBase-input": {
        color: "#FFFFFF",
        fontSize: "14px",
        width: "50px",
        height: "0px",
      },
      "& .MuiInput-underline:after": {
        borderBottomColor: "#54A9FF",
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: "#494C55",
        },
        "&:hover fieldset": {
          borderColor: "#494C55",
        },
        "&.Mui-focused fieldset": {
          borderColor: "#54A9FF",
        },
      },
    },
  },
  tradedBox: {
    background: "#1D4336 0% 0% no-repeat padding-box",
    border: "1px solid #00FF90",
    borderRadius: "4px",
    opacity: "1",
    // height: "24px",
    width: "102px",
    margin: "0px auto",
  },
  customTooltip: {
    height: "200px",
    width: "200px",
    maxHeight: "200px",
    overflowY: "auto",
  },
  customIcon: {
    color: "#FF9E54",
    fontSize: "1.9rem",
  },
  menuPaper: {
    background: "#2D2D2D 0% 0% no-repeat padding-box",
    boxShadow: "0px 6px 8px #000000A1",
    borderRadius: "4px",
    opacity: 1,
  },
  menuItemOption: {
    color: "#FFFFFF",
    fontSize: "14px/17px",
  },
  userAuctionTable: {
    "& .MuiTableCell-sizeSmall": {
      padding: "2px 0px",
    },
  },
}));
