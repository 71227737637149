import React from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { Grid } from "@mui/material";
import { useStyles } from "./ClientInfo.style";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import SearchInput from "../../../ReusableComps/SearchInput/SearchInput";
import Loader from "../../../ReusableComps/loader/Loader";
import moment from "moment";

const ClientInfoView = (props) => {
  const {
    downloadClientCsvFile,
    loadingClients,
    openNewOrEditDialog,
    createNewClient,
    sortedClients,
    filterClients,
  } = props;

  const tableHeaders = [
    "name",
    "lei",
    "created at",
    "Number of users",
    "is active",
  ];

  const classes = useStyles();
  return (
    <Grid item container style={{ padding: "16px" }}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Button className={classes.button} onClick={createNewClient}>
            New
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item style={{ paddingRight: "12px" }}>
            <IconButton
              className={classes.downloadButton}
              onClick={downloadClientCsvFile}
            >
              <DownloadIcon style={{ color: "#54A9FF" }} />
            </IconButton>
          </Grid>
          <Grid item>
            <SearchInput onChange={(e) => filterClients(e)} />
          </Grid>
        </Grid>
      </Grid>
      {/* // ! table place */}
      {sortedClients && sortedClients.length && loadingClients !== true ? (
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <TableContainer
            className={classes.tableStyle}
            style={{ border: "1px solid #3D3F47" }}
            sx={{ height: "calc(100vh - 210px)" }}
          >
            <Table stickyHeader style={{ width: "inherit" }} size="small">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => {
                    return (
                      <TableCell
                        key={index}
                        className={classes.headerTableRow}
                        style={{
                          textTransform:
                            header === "lei" ? "uppercase" : "capitalize",
                        }}
                      >
                        {header}
                      </TableCell>
                    );
                  })}
                  <TableCell className={classes.headerTableRow} />
                </TableRow>
              </TableHead>
              <TableBody className={classes.dataTableBody}>
                {sortedClients.map((client, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.dataTableCell}
                        style={{ maxWidth: "100px" }}
                      >
                        {client.client_name}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {client.lei}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {moment(client.created_at).format(
                          "DD-MMM-YYYY HH:mm:ss"
                        )}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {client.numberOfUsers}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        <Checkbox
                          checked={
                            client.is_active === 0 || client.is_active === false
                              ? false
                              : true
                          }
                          sx={{
                            "&.Mui-checked": {
                              color: "#2e7d32",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        className={classes.dataTableCell}
                        style={{ maxWidth: "1px" }}
                      >
                        <IconButton
                          size={"small"}
                          onClick={() => openNewOrEditDialog(client.client_id)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid
          item
          container
          justifyContent="center"
          style={{ position: "relative", top: "340px" }}
        >
          <Grid item>
            <Loader />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ClientInfoView;
