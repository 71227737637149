import React from "react";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  dialog: {
    minWidth: "897px !important",
    maxWidth: "897px !important",
    height: "822px",
    color: "#FFFFFF !important",
    backgroundColor: "#2D2D2D !important",
  },
  header: {
    textAlign: "left",
    color: "#ffffff",
    font: "normal normal normal 1.33rem/1.33rem Inter",
  },
  selectedBtn: {
    backgroundColor: "#FF9E54",
    color: "#000000",
    font: "normal normal medium 1.166rem/1.416rem Inter",
    textTransform: "none",
    width: "100px",
    height: "32px",
    border: "1px solid #FF9E54",
    "&:hover": {
      backgroundColor: "#cc7e43",
      color: "#000000",
      border: "1px solid #FF9E54",
    },
  },
  buttonGroupBtn: {
    color: "#FF9E54",
    font: "normal normal medium 1.166rem/1.416rem Inter",
    width: "100px",
    height: "32px",
    textTransform: "none",
    backgroundColor: "transparent",
    border: "1px solid #FF9E54",
    "&:hover": {
      backgroundColor: "#FF9E54",
      border: "1px solid #FF9E54",
      color: "#000000",
    },
  },
  uploadButton: {
    textDecoration: "underline",
    textTransform: "Capitalize",
  },
  inputLabelStyle: {
    color: "#757882",
    fontSize: "1rem",
  },
  select: {
    width: "231px",
    height: "32px",
    color: "white",
    backgroundColor: "#2D2D2D",
    textTransform: "capitalize",
    border: "1px solid #494C55",
  },
  headerTableRow: {
    fontSize: "1rem",
    color: "#5a5c62",
    textAlign: "center",
    borderBottom: "1px solid #000000 !important",
    backgroundColor: "#2d2d2d",
  },
  dataTableCell: {
    fontSize: "1rem",
    color: "#ffffff !important",
    textAlign: "center !important",
    borderBottom: "none",
  },
  dataTableBody: {
    "& tr:nth-of-type(odd)": {
      backgroundColor: "#222222 !important",
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: "#2d2d2d !important",
    },
  },
  addButton: {
    background: "#494C55 0% 0% no-repeat padding-box",
    borderRadius: "4px",
    opacity: 1,
    textTransform: "capitalize",
    fontSize: "1.166rem",
    width: "67px",
    height: "32px",
  },
  addIcon: {
    width: "15px",
    height: "15px",
    marginBottom: "-1px",
  },
  timeSelect: {
    width: "94px",
    height: "32px",
    color: "white",
    backgroundColor: "#2D2D2D",
    textTransform: "capitalize",
    border: "1px solid #494C55",
  },
  startInButton: {
    font: "Inter",
    fontSize: "1.166rem",
    width: "147px",
    height: "32px",
    background: " #2D2D2D 0% 0% no-repeat padding-box",
    border: "2px solid #54A9FF",
    borderRadius: "4px",
    textTransform: "capitalize",
    opacity: 1,
  },
  startNowButton: {
    width: "147px",
    height: "32px",
    font: "Inter",
    fontSize: "1.166rem",
    textTransform: "capitalize",
    background: "#54A9FF 0% 0% no-repeat padding-box",
    borderRadius: "4px",
    color: "#000000",
    opacity: 1,
    "&:Hover": {
      backgroundColor: "#9ACCFF",
    },
  },
  textField: {
    // borderRadius: 4,
    "& .MuiInputBase-input": {
      // position: "relative",
      color: "#FFFFFF",
      fontSize: "14px",
      // width: "auto",
      // padding: "5px 18px",
      height: "0px",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#54A9FF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#494C55",
      },
      "&:hover fieldset": {
        borderColor: "#494C55",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#54A9FF",
      },
    },
  },
  auctionPriceTextField: {
 
    "& .MuiInputBase-input": {
 
      color: "#FFFFFF",
      fontSize: "14px",
      width: "50px",
 
      height: "0px",
    },
    "& .MuiInput-underline:after": {
      border:"none"

    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border:"none"
  
      },
      "&:hover fieldset": {
        border:"none"
     
      },
      "&.Mui-focused fieldset": {
        border:"none"

      },
    },
  },
  auctionTimeTextField: {
    // borderRadius: 4,
    "& .MuiInputBase-input": {
      // position: "relative",
      color: "#FFFFFF",
      fontSize: "14px",
      width: "50px",
      // padding: "5px 18px",
      height: "0px",
    },
    "& .MuiInput-underline:after": {
     
      borderBottomColor: "#54A9FF",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
    
        borderColor: "#494C55",
      },
      "&:hover fieldset": {
    
        borderColor: "#494C55",
      },
      "&.Mui-focused fieldset": {
    
        borderColor: "#54A9FF",
      },
    },
  },
}));
