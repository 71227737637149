import React, { useEffect, useState } from "react";
import moment from "moment";
import PapaParse from "papaparse";
import Api from "../../../../apis/logRequest";
import { useSelector } from "react-redux";
import ActivityReportsView from "./ActivityReports.view";

function ActivityReport() {
  let token = sessionStorage.getItem("token");
  const [activityLog, setActivityLog] = useState([]);
  const [activityLogLoading, setActivityLogLoading] = useState("false");
  const [chosenDate, setChosenDate] = useState("");
  const [sortedActivityLog, setSortedActivityLog] = useState([]);
  const [isASD, setIsASD] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDelayed, setSearchTermDelayed] = useState("");
  const [sortedField, setSortedField] = useState("Date Time (UTC)");
  const { viewAsClientUser } = useSelector((state) => state.impersonationSlice);

  // functions
  const onDownloadActivitiesToCsv = () => {
    let activityLogCopy = activityLog
    if(activityLogCopy[1].hasOwnProperty("client_user_id")){
     activityLogCopy = activityLogCopy.map(({ client_user_id, ...rest }) => rest);
    }
    const csv = PapaParse.unparse(activityLogCopy);
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(
        blob,
        `Activity_report_date_${moment(new Date()).format("YYYY-MM-DD")}`
      );
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `Activity_report_date_${moment(new Date()).format("YYYY-MM-DD")}`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const dateSetter = (e) => {
    if (e.target.value !== "") {
      let formattedDate = moment(e.target.value).format("DD-MMM-yyyy");
      setChosenDate(formattedDate);
    } else {
      setChosenDate(moment().format("DD-MMM-yyyy"));
    }
  };

  const handleSorting = (field) => {
    setSortedField(field);
    field = field.toLowerCase();
    if (field) {
      const sorted = [...activityLog].sort((a, b) => {
        if (field === "date time (utc)") {
          const dateA = new Date(a[field]);
          const dateB = new Date(b[field]);
          return !isASD ? dateA - dateB : dateB - dateA;
        } else {
          const valueA = a[field] === null ? "" : a[field];
          const valueB = b[field] === null ? "" : b[field];

          return !isASD
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        }
      });
      setIsASD(!isASD);
      setSortedActivityLog(sorted);
    }
  };

  const getUserLog = async (chosenDate, searchTerm) => {
    setActivityLogLoading(true);
    try {
      let activityLog;
      if (viewAsClientUser !== null) {
        activityLog = await Api.getActivityLog(
          token,
          "activityLog",
          chosenDate,
          searchTerm,
          viewAsClientUser.clientUserId
        );
      } else {
        activityLog = await Api.getActivityLog(
          token,
          "activityLog",
          chosenDate,
          searchTerm
        );
      }
      setActivityLog(activityLog.data);
      setSortedActivityLog(activityLog.data);
      setActivityLogLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const delay = setTimeout(() => {
      setSearchTermDelayed(searchTerm);
    }, 300);

    return () => clearTimeout(delay);
  }, [searchTerm]);

  useEffect(() => {
    getUserLog(chosenDate, searchTermDelayed);
  }, [chosenDate, searchTermDelayed, viewAsClientUser]);

  return (
    <ActivityReportsView
      setChosenDate={setChosenDate}
      activityLog={sortedActivityLog}
      onDownloadActivitiesToCsv={onDownloadActivitiesToCsv}
      dateSetter={dateSetter}
      handleSorting={handleSorting}
      searchTerm={searchTerm}
      setSearchTerm={(newTerm) => setSearchTerm(newTerm)}
      activityLogLoading={activityLogLoading}
      sortedField={{ fieldName: sortedField, asd: isASD }}
    />
  );
}

export default ActivityReport;
