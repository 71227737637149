import "./App.css";
import { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import SnackBar from "./components/Snackbar";
import ChangeBrowser from "./components/utils/ChangeBrowser/ChangeBrowser";
import Login from "./components/main/Login/Login/Login";
import StaticTabs from "./components/main/Static/StaticTabs/StaticTabs";
import Reports from "./components/main//Reports/Reports";
import Workspace from "./components/main/Workspace/Workspace";
import Sidebar from "./components/aside/Sidebar/Sidebar";
import AuthSelector from "./components/main/Login/AuthSelector/AuthSelector";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { ws, connectWS } from "./services/ws/websocket";
import ForgotPassword from "./components/main/ForgotPassword/ForgotPassword";
import { useDispatch, useSelector } from "react-redux";
import * as actionSnackBar from "./store/slice/snackbarSlice";
import ApiLogs from "./apis/logRequest";
import "moment/locale/en-gb";
import AdminsViewAsSelector from "./components/main/AdminsViewAsSelector/AdminsViewAsSelector";
import ClientUserGreet from "./components/main/ClientUserGreet/ClientUserGreet";

function App() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [isChrome, setIsChrome] = useState(false);
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const { tokenType } = useSelector((state) => state.authSlice);
  const { viewAsClientUser, impersonationOn } = useSelector(
    (state) => state.impersonationSlice
  );
  const [isRefreshing, setIsRefreshing] = useState(false);
  const permission = useSelector((state) => +state.userSlice.permission);
  const widthDown900 = useMediaQuery(theme.breakpoints.down("md"));
  let userInfo = JSON.parse(sessionStorage.getItem("userData"));
  const authToken = useSelector((state) => state.authSlice.token);

  const socketMaintainer = () => {
    if (ws !== null) {
      if (ws.readyState === 3) {
        console.log(
          "Connection is to websocket lost, caught by setTimeout => reconnecting..."
        );
        connectWS(token?.replace("Bearer ", ""));
      }
    }

    setTimeout(socketMaintainer, 10000);
  };
  socketMaintainer();

  useEffect(() => {
    const isChrome =
      navigator.userAgentData &&
      navigator.userAgentData.brands.some(
        (brand) => brand.brand === "Google Chrome"
      );
    if (isChrome) {
      setIsChrome(true);
    } else {
      setIsChrome(false);
    }
  }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = async () => {
  //     console.log(token)
  //     console.log("11111111111111111")
  //     // Check if session storage is being cleared
  //     const isAuthenticated = token !== null && token !== undefined && token !== "";
  //     if(token === null || token === undefined || token === ""){
  //       try {
  //         let logDetails = {
  //           type: "Connection Lost",
  //           clientUserId: userInfo.clientUserId,
  //         };
  //         await ApiLogs.sendLog(token, logDetails); 
  //       } catch (error) {
  //         console.log(error)
  //       }
  //     }

  //     // if (isSessionStorageCleared) {
  //     //   // Get the token from session storage
  //     //   const token = sessionStorage.getItem('token');

  //     //   // Send your API request to the server
  //     //   try {
  //     //     await fetch('your-api-endpoint', {
  //     //       method: 'POST',
  //     //       headers: {
  //     //         'Content-Type': 'application/json',
  //     //         Authorization: `Bearer ${token}`,
  //     //       },
  //     //       // Add any additional data as needed
  //     //     });
  //     //   } catch (error) {
  //     //     console.error('Error sending API request:', error);
  //     //   }
  //     // }
  //   };

  //   // Attach the event listener
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [token]); 
  // useEffect(() => {
  //   if ("Notification" in window) {
  //     Notification.requestPermission();
  //   }
  // }, []);

  useEffect(() => {
    if (impersonationOn === true) {
      dispatch(
        actionSnackBar.setSnackBar({
          type: "warning",
          message: `Now view as ${viewAsClientUser.fullName}`,
          timeout: 3000,
          position: "bottom-right",
        })
      );
    } else if (impersonationOn === false) {
      dispatch(
        actionSnackBar.setSnackBar({
          type: "success",
          message: `view as disabled`,
          timeout: 3000,
          position: "bottom-right",
        })
      );
    }
  }, [impersonationOn, viewAsClientUser]);

  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     setIsRefreshing(true);
  //   };

  //   const handleUnload = async () => {
  //     if (isRefreshing) {
  //     } else {
  //       let logDetails = {
  //         type: "Connection Lost",
  //         clientUserId: userInfo.clientUserId,
  //       };
  //       await ApiLogs.sendLog(token, logDetails);
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);
  //   window.addEventListener("unload", handleUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //     window.removeEventListener("unload", handleUnload);
  //   };
  // }, [isRefreshing]);
  const isAuthenticated = token !== null && token !== undefined && token !== "";
  const isAuthUser =
    authToken !== null && authToken !== undefined && authToken !== "";
  return (
    <Grid container style={{ position: "absolute" }}>
      {!isChrome ? (
        <Grid container style={{ height: "100vh", width: "100vw" }}>
          <ChangeBrowser />
        </Grid>
      ) : (
        <Grid
          container
          style={{
            height: "100vh",
            position: widthDown900 ? "none" : "relative",
            left:
              token === "" || token === null
                ? "0"
                : widthDown900
                ? "0px"
                : "72px",
            width:
              token === "" || token === null
                ? "100vw"
                : widthDown900
                ? "100vw"
                : "calc(100vw - 72px)",
          }}
        >
          <SnackBar />
          {token === null ||
          token === undefined ? null : widthDown900 ? null : (
            <Sidebar />
          )}
          {permission === 1 ? <AdminsViewAsSelector /> : null}
          {permission !== 1 && token !== null ? <ClientUserGreet /> : null}
          <Routes>
            {isAuthenticated ? (
              <Route path="workspace" element={<Workspace />} />
            ) : (
              <>
                <Route path="*" element={<Login />} />
                <Route path="/" element={<Login />} />
              </>
            )}
            {isAuthUser && (
              <>
                <Route path="/verify" element={<AuthSelector />} />
                <Route path="/forgot_password" element={<ForgotPassword />} />
              </>
            )}

            {isAuthenticated && (
              <>
                <Route path="client_info" element={<StaticTabs />} />
                <Route path="reports" element={<Reports />} />
                <Route path="workspace" element={<Workspace />} />
              </>
            )}
          </Routes>
          {/* <Routes>
            {token === null || token === undefined ? (
              <Route path="*" element={<Login />} />
            ) : (
              <Route path="workspace" element={<Workspace />} />
            )}

            {authToken !== "" &&
            authToken !== null &&
            authToken !== undefined ? (
              <>
                <Route path="/verify" element={<AuthSelector />} />
                <Route path="/forgot_password" element={<ForgotPassword />} />
              </>
            ) : (
              <Route path="*" element={<Navigate to={"/workspace"} />} />
            )}

            {token !== "" || (token !== null && tokenType === "login") ? (
              <>
                <Route path="client_info" element={<StaticTabs />} />
                <Route path="reports" element={<Reports />} />
                <Route path="workspace" element={<Workspace />} />
              </>
            ) : (
              <Route path="workspace" element={<Workspace />} />
            )}
          </Routes> */}
        </Grid>
      )}
    </Grid>
  );
}

export default App;
