import React from "react";

import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#54A9FF",
    textTransform: "capitalize",
    borderRadius: "4px",
    width: "122px",
    height: "32px",
    color: "#000000",
    fontSize: "1.167rem",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#2692ff",
    },
  },
  downloadButton: {
    width: "32px",
    height: "32px",
    background: "#24414B 0% 0% no-repeat padding-box",
    border: "1px solid #54A9FF",
    borderRadius: "4px",
    opacity: 1,
  },
  textField: {
    borderRadius: 4,
    border: "1px solid #494C55",
    "& .MuiInputBase-input": {
      position: "relative",
      color: "#FFFFFF",
      fontSize: 16,
      width: "auto",
      padding: "5px 18px",
    },
  },
  headerTableRow: {
    fontSize: "1.08rem",
    color: "#5a5c62",
    textAlign: "center",
    borderBottom: "none",
    fontWeight: "600",
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#2d2d2d",
    },
  },
  dataTableBody: {
    "& tr:nth-of-type(odd)": {
      backgroundColor: "#222222",
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: "#2d2d2d",
    },
  },
  dataTableCell: {
    fontSize:"14px",
    color: "#ffffff",
    textAlign: "center",
    borderBottom: "none",
  },
}));
