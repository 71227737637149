import React from "react";
import {
  Typography,
  Grid,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  InputAdornment,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import { ReactComponent as Minimize } from "../../../../assets/icons/collapsAuction.svg";
import { ReactComponent as Expand } from "../../../../assets/icons/expand.svg";
import { ReactComponent as EditButton } from "../../../../assets/icons/editAuction.svg";
import { ReactComponent as CopyButton } from "../../../../assets/icons/copyAuction.svg";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStyles } from "./WorkspaceAuctionRow.style";
import "./WorkspaceAuctionRow.css";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

const WorkspaceAuctionRowView = (props) => {
  const {
    handleToggle,
    auction,
    formattedTimeLeft,
    handleInputBuySellChanges,
    handleBuySellChangesKeyDown,
    handleTotalBuy,
    handleTotalSell,
    handleActiveInterests,
    handleBuyDisabled,
    handleSellDisabled,
    auctions,
    auctionsRef,
    auctionEnded,
    handleTableCellClassStyled,
    anchorEl,

    handleButtonClick,
    handleMenuClose,
    handleMenuItemClick,
    handleCellHover,
    hoveredItem,
    handleCopyButtonClick,
    anchorCopyEl,
    handleCopyMenuItemClick,
    handleCopyMenuClose,
    openEditAuctionDialog,
    handleInputBlur,
    confirmDialogOpen
  } = props;
  const { viewAsClientUser } = useSelector((state) => state.impersonationSlice);
  const permission = useSelector((state) => +state.userSlice.permission);
  const classes = useStyles();
  const bondTraderInfoTableHeaders = ["", "Bond", "Price", "Time"];
  const bondClientInfoTableHeaders = ["Buy", "Bond", "Price", "Sell", "Time"];
  const bondTraderInfoPriceTableHeaders = ["Buy", "Sell", "Active Interests"];
  const bondClientInfoPriceTableHeaders = ["", "Bought", "Sold"];
  const activeInterestsTableHeaders = ["User", "Buy", "Sell"];
  const minWindowWidth = useMediaQuery("(min-width:1360px)");

  const handleActiveInterestsTableHeader = () => {
    return (
      <TableRow>
        {activeInterestsTableHeaders.map((header, index) => {
          return (
            <TableCell
              key={index}
              auction={index}
              className={classes.tooltipHeaderTableRow}
            >
              {header}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const handleActiveInterestsInfoTable = () => {
    return (
      <>
        {hoveredItem?.map((item, index) => {
          return (
            <TableBody className={classes.dataTableBody} key={index}>
              <TableRow key={index}>
                <TableCell className={classes.dataTableCell}>
                  {item.clientFirstName}
                </TableCell>
                <TableCell className={classes.dataTableCell}>
                  {item.buy_size !== undefined ? item.buy_size : ""}
                </TableCell>
                <TableCell className={classes.dataTableCell}>
                  {item.sell_size !== undefined ? item.sell_size : ""}
                </TableCell>
              </TableRow>
            </TableBody>
          );
        })}
      </>
    );
  };

  const tableContent = (
    <TableContainer>
      <Table>
        <TableHead>{handleActiveInterestsTableHeader()}</TableHead>
        {handleActiveInterestsInfoTable()}
      </Table>
    </TableContainer>
  );

  const handleBondInfoDetailsTable = () => {
    if ((permission === 1 || permission === "1") && viewAsClientUser === null) {
      return (
        <>
          {auction?.items?.map((item, index) => {
            const isTraded = item.traded === true;
            const isVisible = item.visible === true;
            const bondTradeExtraTime = item.bondTradeExtraTime;
            const isBlind = item.is_blind;

            return (
              <TableBody
                className={
                  item.IsBlindColor === true && item.IsBlindColor !== undefined
                    ? classes.blindAuctionClass
                    : index % 2 === 0
                      ? classes.dataTableBodyEven
                      : classes.dataTableBody
                }
              >
                <TableRow
                  key={index}
                  className={handleTableCellClassStyled(
                    isTraded,
                    isVisible,
                    bondTradeExtraTime,
                    auction.phrase,
                    isBlind
                  )}
                >
                  <TableCell
                    className={classes.dataTableCell}
                    style={{ width: "20ch" }}
                  >
                    <Grid className={item.tradedText ? classes.tradedBox : ""}>
                      <Typography
                        style={{
                          color: "#00FF90",
                          fontSize: "14px",
                        }}
                      >
                        {item.tradedText ? "Traded!" : ""}
                      </Typography>
                    </Grid>
                  </TableCell>
                  <TableCell
                    className={classes.dataTableCell}
                    style={{ width: "45ch" }}
                  >
                    {item.bond_name + " " + item.currency}
                  </TableCell>
                  <TableCell className={classes.dataTableCell}>
                    {item.auction_price}
                  </TableCell>
                  <TableCell className={classes.dataTableCell}>
                    {item.remainingTime}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          {auction?.items?.map((item, index) => {
            const tableBodyClass =
              item.IsBlindColor === true && item.IsBlindColor !== undefined
                ? classes.blindAuctionClass
                : index % 2 === 0
                  ? classes.dataTableBodyEven
                  : classes.dataTableBody;

            const rowClassName =
              (item.is_traded ? "tradedBonds " : "") +
              (item.is_visible ? "addVisibleBonds " : "") +
              tableBodyClass;

            return (
              <TableBody className={rowClassName}>
                <TableRow
                  key={index}
                  className={handleTableCellClassStyled(
                    item.traded,
                    item.visible,
                    item.bondTradeExtraTime,
                    item.phrase,
                    item.is_blind
                  )}
                >
                  <TableCell className={classes.dataTableCell}>
                    <FormControl
                      sx={{
                        height: "fit-content",
                        width: "10ch",
                        borderRadius: "4px",
                        "&:focus-within": {
                          border: "none",
                        },
                        mx: 1,
                        my: 0.3,
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        style={{
                          color: item.confirmedBuy ? "#54A9FF" : "#ffffff",
                        }}
                        sx={{
                          height: "25px",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#65696E",
                          },
                          "&:hover > .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#54A9FF",
                          },
                        }}

                        className={
                          handleBuyDisabled(
                            item.buy_size,
                            item.sell_size,
                            item?.phrase,
                            item.bondTradeExtraTime
                          )
                            ? classes.auctionPriceTextFieldDisabled
                            : classes.auctionPriceTextField
                        }
                        type="text"
                        endAdornment={
                          item.buy_size === "" ||
                            item.buy_size === "0" ||
                            item.buy_size === null ||
                            item.buy_size === undefined ? null : (
                            <InputAdornment
                              position="end"
                              style={{ width: "13px" }}
                            >

                              {item.confirmedBuy && (
                                <IconButton size="small">
                                  <CloseIcon
                                    onClick={(e) =>
                                      handleMenuItemClick(
                                        "buy",
                                        auction?.items[0]?.auction_id,
                                        "cancel one",
                                        auction,
                                        item.auctionStaticId,
                                        item.bond_name,
                                        item.currency,
                                        item.buy_size,
                                        item.auction_price
                                      )
                                    }
                                    style={{ color: "#FF9E54", fontSize: "14px" }}
                                  />
                                </IconButton>
                              )}
                            </InputAdornment>
                          )
                        }
                        disabled={handleBuyDisabled(
                          item.buy_size,
                          item.sell_size,
                          item?.phrase,
                          item.bondTradeExtraTime
                        )}

                        value={
                          item.buy_size === "0" ||
                            item.buy_size === null ||
                            item.buy_size === undefined
                            ? ""
                            : item.buy_size
                        }
                        onChange={(e) =>
                          handleInputBuySellChanges(
                            e,
                            "buy",
                            auction.auction_id,
                            index,
                            item.auctionStaticId,
                            item.bond_name,
                            item.currency,
                            item.auction_price,
                            item.isin,
                            item.buy_size,
                            item.copy_buy_size

                          )
                        }
                        onKeyDown={(e) =>
                          handleBuySellChangesKeyDown(
                            e,
                            "buy",
                            auction.auction_id,
                            index,
                            item.auctionStaticId,
                            item.buy_size,
                            item.copy_buy_size
                          )
                        }

                        onBlur={(e) => {

                          handleInputBlur(e, item.buy_size, item.auctionStaticId, "buy")


                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell
                    className={classes.dataTableCell}
                    style={{ width: "220px" }}
                  >
                    {item.bond_name + " " + item.currency}
                  </TableCell>
                  <TableCell className={classes.dataTableCell}>
                    {item.auction_price}
                  </TableCell>

                  <TableCell className={classes.dataTableCell}>
                    <FormControl
                      sx={{
                        height: "fit-content",
                        width: "10ch",
                        borderRadius: "4px",
                        mx: 1,
                        my: 0.3,
                      }}
                      variant="outlined"
                    >
                      <OutlinedInput
                        style={{
                          color: item.confirmedSell ? "#54A9FF" : "#ffffff",
                        }}
                        sx={{
                          height: "25px",

                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#65696E",
                          },
                          "&:hover > .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#54A9FF",
                          },
                        }}
                        className={
                          handleSellDisabled(
                            item.buy_size,
                            item.sell_size,
                            item.phrase,
                            item.bondTradeExtraTime
                          )
                            ? classes.auctionPriceTextFieldDisabled
                            : classes.auctionPriceTextField
                        }
                        type="text"
                        endAdornment={
                          item.sell_size === "" ||
                            item.sell_size === "0" ||
                            item.sell_size === null ||
                            item.sell_size === undefined ? null : (
                            <InputAdornment
                              position="end"
                              style={{ width: "13px" }}
                            >

                              {item.confirmedSell && (
                                <IconButton size="small">
                                  <CloseIcon
                                    onClick={(e) =>
                                      handleMenuItemClick(
                                        "sell",
                                        auction?.items[0]?.auction_id,
                                        "cancel one",
                                        auction,
                                        item.auctionStaticId,
                                        item.bond_name,
                                        item.currency,
                                        item.sell_size,
                                        item.auction_price
                                      )
                                    }
                                    style={{ color: "#FF9E54", fontSize: "14px" }}
                                  />
                                </IconButton>
                              )}
                            </InputAdornment>
                          )
                        }
                        value={
                          item.sell_size === null ||
                            item.sell_size === "0" ||
                            item.sell_size === undefined
                            ? ""
                            : item.sell_size
                        }
                        disabled={handleSellDisabled(
                          item.buy_size,
                          item.sell_size,
                          item.phrase,
                          item.bondTradeExtraTime
                        )}
                        onChange={(e) =>
                          handleInputBuySellChanges(
                            e,
                            "sell",
                            auction.auction_id,
                            index,
                            item.auctionStaticId,
                            item.bond_name,
                            item.currency,
                            item.auction_price,
                            item.isin,
                            item.sell_size,
                            item.copy_sell_size
                          )
                        }
                        onKeyDown={(e) =>
                          handleBuySellChangesKeyDown(
                            e,
                            "sell",
                            auction.auction_id,
                            index,
                            item.auctionStaticId,
                            item.sell_size,
                            item.copy_buy_size
                          )
                        }
                        onBlur={(e) => {
                          handleInputBlur(e, item.buy_size, item.auctionStaticId, "sell")
                        }}
                      />
                    </FormControl>
                  </TableCell>
                  <TableCell className={classes.dataTableCell}>

                    {item.remainingTime}
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </>
      );
    }
  };

  const handleBondInfoTable = () => {
    if ((permission === 1 || permission === "1") && viewAsClientUser === null) {
      return (
        <TableRow>
          {bondTraderInfoTableHeaders.map((header, index) => {
            return (
              <TableCell
                key={index}
                auction={index}
                className={classes.headerTableRow}
                style={{
                  textTransform: "uppercase",
                }}
              >
                {header}
              </TableCell>
            );
          })}
        </TableRow>
      );
    } else {
      return (
        <TableRow>
          {bondClientInfoTableHeaders.map((header, index) => {
            return (
              <TableCell
                key={index}
                auction={index}
                className={classes.headerTableRow}
                style={{
                  textTransform: "uppercase",
                }}
              >
                {header}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
  };

  const handleBondPriceTable = () => {
    if ((permission === 1 || permission === "1") && viewAsClientUser === null) {
      return (
        <TableRow>
          {bondTraderInfoPriceTableHeaders.map((header, index) => {
            return (
              <TableCell
                key={index}
                auction={index}
                className={classes.headerTableRow}
                style={{
                  textTransform: "uppercase",
                }}
              >
                {header}
              </TableCell>
            );
          })}
        </TableRow>
      );
    } else {
      return (
        // <></>
        <TableRow>
          {bondClientInfoPriceTableHeaders.map((header, index) => {
            return (
              <TableCell
                key={index}
                auction={index}
                className={classes.headerTableRow}
                style={{
                  textTransform: "uppercase",
                }}
              >
                {header}
              </TableCell>
            );
          })}
        </TableRow>
      );
    }
  };

  const handleBondPriceInfoDetailsTable = () => {
    if ((permission === 1 || permission === "1") && viewAsClientUser === null) {
      return (
        <>
          {auction?.items?.map((item, index) => {
            const isTraded = item.traded === true;
            return (
              <TableBody
                className={
                  index % 2 === 0
                    ? classes.dataTableBodyEven
                    : classes.dataTableBody
                }
                key={index}
              >
                <TableRow key={index} className={isTraded ? "tradedBonds" : ""}>
                  <TableCell className={classes.dataTableCell}>
                    {handleTotalBuy(item.client_user_id_buy)}
                  </TableCell>
                  <TableCell className={classes.dataTableCell}>
                    {handleTotalSell(item.client_user_id_sell)}
                  </TableCell>

                  <TableCell
                    className={`${classes.dataTableCell} ${classes.activeInterest}`}
                  >
                    <Tooltip
                      title={tableContent}
                      classes={{ tooltip: classes.customTooltip }}
                    >
                      <span onMouseOver={() => handleCellHover(item)}>
                        {handleActiveInterests(
                          item.client_user_id_buy,
                          item.client_user_id_sell
                        )}
                      </span>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </>
      );
    } else {
      return (
        <>
          {auction?.items?.map((item, index) => {
            const isTraded = item.traded === true;
            
            if (Number(item.index) === Number(index)) {
              return (
                <TableBody
                  className={
                    index % 2 === 0
                      ? classes.dataTableBodyEven
                      : classes.dataTableBody
                  }
                  key={index}
                >
                  <TableRow
                    style={{ height: "fit-content" }}
                    key={index}
                    className={isTraded ? "tradedBonds" : ""}
                  >
                    <TableCell
                      className={classes.boughtSoldTableCell}
                      style={{ width: "30ch" }}
                    >
                      <Grid
                        className={item.tradedText ? classes.tradedBox : ""}
                      >
                        <Typography
                          style={{ color: "#00FF90", fontSize: "14px" }}
                        >
                          {item.tradedText ? "Traded!" : ""}
                        </Typography>
                      </Grid>
                    </TableCell>
                    <TableCell
                      className={classes.boughtSoldTableCell}
                      style={{ width: "25ch" }}
                    >
                      {item.totalBought === "null" || item.bought === undefined
                        ? ""
                        : item.totalBought}
                    </TableCell>
                    <TableCell
                      className={classes.boughtSoldTableCell}
                      style={{ width: "25ch" }}
                    >
                      {item.totalSold === "null" || item.sold === undefined
                        ? ""
                        : item.totalSold}
                    </TableCell>
                  </TableRow>
                </TableBody>
              );
            } else {
              return (
                <TableRow
                  key={item.index}
                  className={item.traded ? "tradedBonds" : ""}
                >
                  <TableCell className={classes.dataTableCell}></TableCell>
                  <TableCell className={classes.dataTableCell}></TableCell>
                  <TableCell className={classes.dataTableCell}>
                    <Typography
                      style={{ color: "#00FF90", fontSize: "14px" }}
                    ></Typography>
                  </TableCell>
                </TableRow>
              );
            }
          })}
        </>
      );
    }
  };

  return (
    <Grid
      container
      className={classes.auctionCollapseGrid}
      alignItems="center"
      justifyContent="space-between"
      style={{ paddingTop: 5 }}
    >
      <Grid container item xs={5} md={3} alignItems="center">
        <Grid item style={{ marginLeft: "10px", paddingRight: 4 }}>
          <IconButton
            size="small"
            onClick={() => handleToggle(auction.auction_id)}
            style={{
              background: "#2D2D2D",
              borderRadius: "4px",
            }}
          >
            {auction.open ? <Minimize /> : <Expand />}
          </IconButton>
        </Grid>
        <Grid item>
          <Typography
            style={{
              color: "White",
              textTransform: "capitalize",
            }}
          >
            {auction?.items[0]?.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={auction?.phrase?.includes("Time before start") ? 5 : 4}
        md={auction?.phrase?.includes("Time before start") ? 3 : 2}
        className={
          auction?.phrase?.includes("Time before start")
            ? classes.timeBeforeStart
            : auction?.phrase?.includes("Time Left")
              ? classes.timeLeft
              : classes.endedTimeLeft
        }
      >
        <Typography
          style={{
            textAlign: "center",
            fontSize: "1.16rem",
          }}
        >
          {auction?.phrase?.includes("Time before start") ? (
            <span style={{ color: "#BF81FF" }}>
              Time before start:
              <span style={{ color: "#ffffff" }}>
                {auction?.formattedTimeLeft}
              </span>
            </span>
          ) : auction?.phrase?.includes("Time Left") ? (
            <span style={{ color: "#54A9FF" }}>
              {auction.phrase}
              <span style={{ color: "#ffffff" }}>
                {auction?.formattedTimeLeft}
              </span>
            </span>
          ) : auction?.phrase?.includes("Extra time") ? (
            <span style={{ color: "#FF3939" }}>
              <span>{auction?.formattedTimeLeft}</span>
            </span>
          ) : ""
          }
        </Typography>
      </Grid>
      <Grid item xs={2} container justifyContent="flex-end" alignItems="center">
        {(permission === 1 || permission === "1") &&
          viewAsClientUser === null ? (
          <>
            <Grid item style={{ paddingRight: "3px" }}>
              <IconButton size="small">
                <Button
                  style={{ padding: 0, minWidth: 29 }}
                  onClick={handleCopyButtonClick}
                >
                  <CopyButton />
                </Button>
              </IconButton>
              <Menu
                anchorEl={anchorCopyEl}
                open={Boolean(anchorCopyEl)}
                onClose={handleCopyMenuClose}
                getContentAnchorEl={null}
                classes={{ paper: classes.menuPaper }}
              >
                <MenuItem
                  className={classes.menuItemOption}
                  onClick={() =>
                    handleCopyMenuItemClick(
                      "copy Interests",
                      auction?.items[0]?.auction_id
                    )
                  }
                >
                  Copy Interests
                </MenuItem>
                <MenuItem
                  className={classes.menuItemOption}
                  onClick={() =>
                    handleCopyMenuItemClick(
                      "copy auction",
                      auction?.items[0]?.auction_id
                    )
                  }
                >
                  Copy Auction
                </MenuItem>
              </Menu>
            </Grid>
            <Grid item xs={3}>
              <IconButton
                className={classes.editButton}
                size="small"
                disabled={auction?.formattedTimeLeft === "Extra time" ? true : false}
              >
                <EditButton
                  onClick={() =>
                    openEditAuctionDialog(auction?.items[0]?.auction_id)
                  }
                />
              </IconButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              item
              // xs={12}
              container
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={6}>
                <Button
                  onClick={handleButtonClick}
                  className={classes.cancelBuySellButton}
                  endIcon={
                    <ExpandMoreIcon style={{ marginInlineStart: "-9px" }} />
                  }
                >
                  Cancel
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  getContentAnchorEl={null}
                  classes={{ paper: classes.menuPaper }}
                >
                  <MenuItem
                    className={classes.menuItemOption}
                    onClick={() =>
                      handleMenuItemClick(
                        "cancel buy",
                        auction?.items[0]?.auction_id,
                        "cancel all",
                        auction,

                      )
                    }
                  >
                    Cancel All BUY
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItemOption}
                    onClick={() =>
                      handleMenuItemClick(
                        "cancel sell",
                        auction?.items[0]?.auction_id,
                        "cancel all",
                        auction,

                      )
                    }
                  >
                    Cancel All SELL
                  </MenuItem>
                  <MenuItem
                    className={classes.menuItemOption}
                    onClick={() =>
                      handleMenuItemClick(
                        "cancel all",
                        auction?.items[0]?.auction_id,
                        "cancel all",
                        auction,

                      )
                    }
                  >
                    Cancel All
                  </MenuItem>
                </Menu>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.inMillions}>
                  (in millions)
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <Collapse in={auction.open} unmountOnExit>
          <Grid
            item
            container
            xs={12}
            style={{ marginTop: "10px" }}
            justifyContent="space-between"
          >
            <Grid
              item
              xs={minWindowWidth ? 8 : 7}
              className={classes.bondInfoGrid}
            >
              <TableContainer
              // style={{ border: "1px solid #3D3F47"}}
              >
                <Table
                  style={{ width: "inherit" }}
                  size="small"
                  className={classes.userAuctionTable}
                >
                  <TableHead>{handleBondInfoTable()}</TableHead>
                  {handleBondInfoDetailsTable()}
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              xs={minWindowWidth ? 3.8 : 4.8}
              item
              className={classes.bondBidAndSellGrid}
            >
              <TableContainer>
                <Table
                  style={{ width: "inherit" }}
                  size="small"
                  className={classes.userAuctionTable}
                >
                  <TableHead>{handleBondPriceTable()}</TableHead>
                  {handleBondPriceInfoDetailsTable()}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

export default WorkspaceAuctionRowView;

