import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  clientGreet: {
    marginTop: "18px !important",
    fontSize: "1.3rem !important",
    color: "#5a5c62",
    textTransform: "capitalize",
  },
}));
