import React from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Button,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
} from "@mui/material";
import { useStyles } from "./NewAuctionDialog.style";
import ButtonGroup from "@mui/material/ButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as UploadFile } from "../../../../../assets/icons/upload-file.svg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import DeleteIcon from "@mui/icons-material/Delete";

import clsx from "clsx";

const NewAuctionDialogView = (props) => {
  const classes = useStyles();
  const {
    tableData,
    closeModal,
    dialogOpen,
    isBlind,
    setIsBlind,
    handleFileUpload,
    handleButtonClick,
    auctionTime,
    handleDecrement,
    handleIncrement,
    handleBondPriceEnter,
    arrangeBondAndSend,
    onDeleteDuplicatedIsin
  } = props;

  const tableHeaders = [
    "Region",
    "ISIN",
    "Bond Name",
    "Currency",
    "Last Price",
    "Auction Price",
    "Delete",
  ];

  return (
    <Dialog
      open={dialogOpen}
      classes={{ paper: classes.dialog }}
      onClose={closeModal}
    >
      <Grid container style={{ padding: "28px 48px" }}>
        {/* header section */}
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.header}>New Auction</Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={closeModal}>
              <CloseIcon style={{ color: "#ffffff" }} />
            </IconButton>
          </Grid>
        </Grid>
        {/* Region and button group */}
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          alignItems={"center"}
          style={{ paddingTop: "10px" }}
        >
          <ButtonGroup>
            <Button
              className={clsx(
                isBlind === true ? classes.selectedBtn : classes.buttonGroupBtn
              )}
              onClick={() => setIsBlind(true)}
            >
              Blind
            </Button>
            <Button
              className={clsx(
                isBlind === false ? classes.selectedBtn : classes.buttonGroupBtn
              )}
              onClick={() => setIsBlind(false)}
            >
              Visible
            </Button>
          </ButtonGroup>
        </Grid>
        {/* Upload Button */}
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          style={{ paddingTop: "10px" }}
        >
          <Grid item>
            <Button
              className={classes.uploadButton}
              startIcon={<UploadFile />}
              onClick={handleButtonClick}
            >
              <input
                type="file"
                accept=".csv"
                style={{ display: "none" }}
                id="fileInput"
                onChange={handleFileUpload}
              />
              Upload File
            </Button>
          </Grid>
        </Grid>
        {/* Bonds Table */}
        <Grid item container style={{ paddingTop: "10px" }}>
          <TableContainer
            style={{ border: "1px solid #3D3F47", height: "505px" }}
          >
            <Table stickyHeader style={{ width: "inherit" }} size="small">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => {
                    return (
                      <TableCell key={index} className={classes.headerTableRow}>
                        {header}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.dataTableBody}>
                {tableData?.map((bondItem, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className={
                          bondItem.duplicate === true
                            ? classes.duplicatedDataTableCell
                            : classes.dataTableCell
                        }
                        style={{
                          alignContent: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {bondItem.region}
                      </TableCell>
                      <TableCell
                        className={
                          bondItem.duplicate
                            ? classes.duplicatedDataTableCell
                            : classes.dataTableCell
                        }
                      >
                        {bondItem.isin}
                      </TableCell>
                      <TableCell
                        className={
                          bondItem.duplicate
                            ? classes.duplicatedDataTableCell
                            : classes.dataTableCell
                        }
                      >
                        {bondItem.bond_name}
                      </TableCell>
                      <TableCell
                        className={
                          bondItem.duplicate
                            ? classes.duplicatedDataTableCell
                            : classes.dataTableCell
                        }
                      >
                        {bondItem.currency}
                      </TableCell>
                      <TableCell
                        className={
                          bondItem.duplicate
                            ? classes.duplicatedDataTableCell
                            : classes.dataTableCell
                        }
                      >
                        {bondItem.last_price}
                      </TableCell>
                      <TableCell
                        className={
                          bondItem.duplicate
                            ? classes.duplicatedDataTableCell
                            : classes.dataTableCell
                        }
                      >
                        <TextField
                          type="text"
                          value={
                            bondItem.bond_price !== "" && bondItem.bond_price
                          }
                          className={classes.auctionPriceTextField}
                          onChange={(e) => handleBondPriceEnter(e, bondItem)}
                        />
                      </TableCell>
                      <TableCell
                        className={
                          bondItem.duplicate
                            ? classes.duplicatedDataTableCell
                            : classes.dataTableCell
                        }
                      >
                        {bondItem.duplicate ? (
                          <IconButton onClick={() => onDeleteDuplicatedIsin(bondItem.bondId)}>
                            <DeleteIcon style={{ color: "#FF3939" }} />
                          </IconButton>
                        ) : null}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        {/* Bottom Section */}
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ paddingTop: "60px" }}
        >
          <Grid
            item
            xs={5}
            container
            alignItems="center"
            // justifyContent="space-between"
          >
            <Grid item xs={4}>
              <Typography>Auction Time:</Typography>
            </Grid>
            <Grid item>
              <Grid
                item
                container
                alignItems="center"
                style={{ paddingRight: "31px" }}
              >
                <Grid item>
                  <IconButton onClick={handleDecrement}>
                    <RemoveIcon style={{ color: "#2692ff" }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <TextField
                    value={auctionTime}
                    className={classes.auctionPriceTextField}
                    readOnly
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={handleIncrement}>
                    <AddIcon style={{ color: "#2692ff" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} container justifyContent="space-between">
            <Grid item>
              <Button
                className={classes.startInButton}
                onClick={() => arrangeBondAndSend(true)}
              >
                Start In 5
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.startNowButton}
                onClick={(event) => {
                  arrangeBondAndSend(false);
                }}
              >
                Start Now
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default NewAuctionDialogView;
// event.currentTarget.disabled = true;
