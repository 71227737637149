import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import ReportsView from "./Reports.view";
import * as userSlice from "../../../store/slice/userSlice";
import { useNavigate } from "react-router-dom";
import ApiUsers from "../../../apis/userRequest";
import Api from "../../../apis/reportsRequest";

function Reports() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let token = sessionStorage.getItem("token");
  const [currentTab, setCurrentTab] = useState("trades");

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const apiPreRequestPermission = async () => {
    try {
      const result = await ApiUsers.pathAuthRequest(token);
      dispatch(userSlice.updateUserDetails(result.data));
    } catch (error) {
      if (error.response.data.status === 498) {
        dispatch(userSlice.clearLoginState());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    apiPreRequestPermission();
  }, []);

  return (
    <ReportsView currentTab={currentTab} handleTabChange={handleTabChange} />
  );
}

export default Reports;
