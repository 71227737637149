import { Typography, Grid, Tabs } from "@mui/material";
import React, { useState } from "react";
import { useStyles } from "./StaticTabs.style";
import { TabContext } from "@mui/lab";
import ClientInfo from "../ClientInfo/ClientInfo";
import ClientUserInfo from "../ClientUserInfo/ClientUserInfo";
import Instruments from "../Instruments/Instruments";
import {
  StyledTab,
  StyledTabPanel,
} from "../../../ReusableComps/Tabs/TabsAndTabsPanel";

const StaticTabsView = () => {
  const classes = useStyles();
  const [value, setValue] = useState("clients");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <Grid container style={{ width: "calc(100vw - 74px" }}>
      <Grid item style={{ paddingBottom: "10px" }}>
        <Typography className={classes.title}>Static</Typography>
      </Grid>
      <Grid item container>
        <TabContext value={value}>
          <Grid item container>
            <Tabs value={value} onChange={handleChange}>
              <StyledTab label="clients" value="clients" />
              <StyledTab label="users" value="users" />
              <StyledTab label="instruments" value="instruments" />
            </Tabs>
          </Grid>
          <StyledTabPanel value="clients">
            <ClientInfo />
          </StyledTabPanel>
          <StyledTabPanel value="users">
            <ClientUserInfo />
          </StyledTabPanel>
          <StyledTabPanel value="instruments">
            <Instruments />
          </StyledTabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};

export default StaticTabsView;
