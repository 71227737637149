import React, { useState, useEffect } from "react";
import InstrumentsView from "./Instruments.view";
import { Grid } from "@mui/material";
import PapaParse from "papaparse";
import moment from "moment";
import EditInstrumentsDialog from "../Dialogs/EditInstrumentsDialog/EditInstrumentsDialog";
import axios from "axios";
import ApiBonds from "../../../../apis/bondRequest";
import * as snackBarSlice from "../../../../store/slice/snackbarSlice";
import { useDispatch } from "react-redux";

const Instruments = () => {
  const tableHeaders = ["Region", "ISIN", "Bond Name", "Currency"];
  const [dialogOpen, setDialogOpen] = useState(false);
  const [sortedBonds, setSortedBonds] = useState();
  const [loadingBonds, setLoadingBonds] = useState(false);
  const [bonds, setBonds] = useState();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");

  const apiGetAllBonds = async () => {
    try {
      let result = await ApiBonds.getAll(token);
      setBonds(result.data);
      setSortedBonds(result.data);
      setLoadingBonds(false);
    } catch (error) {
      dispatch(
        snackBarSlice.setSnackBar({
          type: "error",
          message: error.response.data,
          timeout: 3000,
        })
      );
    }
  };
  const updateBonds = (bondsToUpdate) => {
    apiGetAllBonds();
  };

  const openNewOrEditDialog = () => {
    setDialogOpen(true);
  };
  const filterBonds = (e) => {
    if (e.target.value === "") {
      setSortedBonds(bonds);
    } else {
      let filtered = bonds.filter((bond) => {
        const searchTerm = e.target.value.toLowerCase();
        for (const key in bond) {
          if (bond[key]?.toString().toLowerCase().includes(searchTerm)) {
            return true;
          }
        }
        return false;
      });
      setSortedBonds(filtered);
    }
  };
  const editBond = () => {
    setDialogOpen(true);
  };
  const closeModal = () => {
    setDialogOpen(false);
  };

  const downloadBondsCsvFile = () => {
    const csv = PapaParse.unparse(sortedBonds);
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(
        blob,
        `Bonds_instruments_${moment(new Date()).format("YYYY-MM-DD")}`
      );
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `bonds_instruments_${moment(new Date()).format("YYYY-MM-DD")}`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
  useEffect(() => {
    apiGetAllBonds();
  }, []);

  return (
    <Grid>
      <InstrumentsView
        filterBonds={filterBonds}
        openNewOrEditDialog={openNewOrEditDialog}
        downloadBondsCsvFile={downloadBondsCsvFile}
        tableHeaders={tableHeaders}
        sortedBonds={sortedBonds}
        loadingBonds={loadingBonds}
        editBond={editBond}
        bonds={bonds}
      />
      <EditInstrumentsDialog
        closeModal={closeModal}
        dialogOpen={dialogOpen}
        updateBonds={updateBonds}
      />
    </Grid>
  );
};

export default Instruments;
