import React, { useEffect, useState } from "react";
import ClientUserInfoView from "./ClientUserInfo.view";
import clientRequest from "../../../../apis/clientRequest";
import NewEditClientUserDialog from "../Dialogs/NewEditClientUserInfoDialog/NewEditClientUserDialog";
import PapaParse from "papaparse";
import moment from "moment";

const ClientUserInfo = () => {
  const token = sessionStorage.getItem("token");
  const [clientsUsers, setClientsUsers] = useState();
  const [loadingClients, setLoadingClients] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("new");
  const [chosenClientId, setChosenClientId] = useState();
  const [sortedClientsUsers, setSortedClientsUsers] = useState();

  const updateClientsUsers = async (newClientObj) => {
    await getAllUsersClients();
    setDialogOpen(false);
  };

  const getAllUsersClients = async () => {
    try {
      const result = await clientRequest.getAllClientsUsers(token);
      if (result.data.length > 0) {
        setClientsUsers(result.data);
        setSortedClientsUsers(result.data);
        setLoadingClients(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const openNewOrEditDialog = (clientUserId) => {
    if (clientUserId) {
      setDialogOpen(true);
      
      setChosenClientId(clientUserId);
      setDialogType("edit");
     
    } else {
      setDialogType("new");
      setDialogOpen(true);
    }

    // setDialogOpen(true);
  };

  const closeModal = () => {
    setDialogOpen(false);
  };

  const filterClientsUsers = (e) => {
    if (e.target.value === "") {
      setSortedClientsUsers(clientsUsers);
    } else {
      let filtered = clientsUsers.filter((client) => {
        const searchTerm = e.target.value.toLowerCase();
        for (const key in client) {
          if (client[key]?.toString().toLowerCase().includes(searchTerm)) {
            return true;
          }
        }
        return false;
      });

      setSortedClientsUsers(filtered);
    }
  };

  const downloadClientsUsersCsvFile = () => {
    const csv = PapaParse.unparse(sortedClientsUsers);
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(
        blob,
        `Bonds_Clients_Users${moment(new Date()).format("YYYY-MM-DD")}`
      );
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `Bonds_Clients_Users${moment(new Date()).format("YYYY-MM-DD")}`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const createNewClientUser = () => {
    setChosenClientId();
    setDialogType("new");
    setDialogOpen(true);
  };

  useEffect(() => {
    getAllUsersClients();
  }, []);
  return (
    <>
      <ClientUserInfoView
        clientsUsers={clientsUsers}
        loadingClients={loadingClients}
        openNewOrEditDialog={openNewOrEditDialog}
        filterClientsUsers={filterClientsUsers}
        sortedClientsUsers={sortedClientsUsers}
        downloadClientsUsersCsvFile={downloadClientsUsersCsvFile}
        createNewClientUser={createNewClientUser}
      />
      <NewEditClientUserDialog
        dialogType={dialogType}
        dialogOpen={dialogOpen}
        clientUserId={chosenClientId}
        setDialogOpen={setDialogOpen}
        closeModal={closeModal}
        updateClientsUsers={updateClientsUsers}
      />
    </>
  );
};

export default ClientUserInfo;
