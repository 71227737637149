import React from "react";
import ConfirmationDialogView from "../ConfirmationDialog/ConfirmationDialog.view"

const ConfirmationDialog = (props) => {
  const { confirmDialogOpen,
    setConfirmDialogOpen,
    closeConfirmModal,
    handleConfirmButtonClick,
    buySellInfo,

  } =
    props;

  return (
    <ConfirmationDialogView
      confirmDialogOpen={confirmDialogOpen}
      setConfirmDialogOpen={setConfirmDialogOpen}
      closeConfirmModal={closeConfirmModal}
      handleConfirmButtonClick={handleConfirmButtonClick}
      buySellInfo={buySellInfo}
    />
  );
};

export default ConfirmationDialog;
