import React, { useState } from "react";
import EditAuctionDialogView from "./EditAuctionDialog.view";
import * as snackBarSlice from "../../../../../store/slice/snackbarSlice";
import {  useSelector,useDispatch } from "react-redux";


const EditAuctionDialog = (props) => {
const dispatch = useDispatch()
  const { editAuctiondialogOpen, closeEditAuctionModal,editAuctionId,auctions,editAuctionTableData,setEditAuctionTableData,apiUpdateAuction} =
    props;
  const [auctionTime, setAuctionTime] = useState("01:00");

  const targetAuction = auctions?.find(auction => Number(auction.auction_id) === Number(editAuctionId));

  setEditAuctionTableData(targetAuction?.items)

  const handleIncrement = () => {
    if (auctionTime === "20:00") return;
    const [minutes, seconds] = auctionTime.split(":").map(Number);
    let newMinutes = minutes + 1;
    let newSeconds = seconds;
    setAuctionTime(
      `${newMinutes.toString().padStart(2, "0")}:${newSeconds
        .toString()
        .padStart(2, "0")}`
    );
  };

  const handleDecrement = () => {
    if (auctionTime === "05:00") return;
    const [minutes, seconds] = auctionTime.split(":").map(Number);
    let newMinutes = minutes - 1;
    let newSeconds = seconds;
    setAuctionTime(
      `${newMinutes.toString().padStart(2, "0")}:${newSeconds
        .toString()
        .padStart(2, "0")}`
    );
  };
  const isFutureDate = (dateString) => {
    const givenDate = new Date(dateString);
    const currentDate = new Date();
    return givenDate > currentDate;
  };

  
  const arrangeBondAndSend = async (buttonType) => {
    setEditAuctionTableData((prevEditAuctionTableData) => {
      const checkIdEndTimeIsInTheFuture = isFutureDate(prevEditAuctionTableData[0]?.end_time);
  
      if (checkIdEndTimeIsInTheFuture) {
        const auctionObject = {
          bonds: prevEditAuctionTableData,
          auction_time: parseInt(auctionTime.split(":")[0], 10),
          buttonType
        };
  
        // Perform the API call
        apiUpdateAuction(auctionObject)
          .then(() => {
            // Handle success if needed
          })
          .catch((error) => {
            dispatch(
              snackBarSlice.setSnackBar({
                type: "error",
                message: "Error updating auction",
                timeout: 3000,
              })
            );
          });
  
        return prevEditAuctionTableData;
      } else {
        dispatch(
          snackBarSlice.setSnackBar({
            type: "error",
            message: "Error: End time is not in the future",
            timeout: 3000,
          })
        );
  
        return prevEditAuctionTableData;
      }
    });
  };
  
  // const arrangeBondAndSend = async (buttonType) => {
  //   let auctionObject = {
  //     bonds: editAuctionTableData,
  //     auction_time: parseInt(auctionTime.split(":")[0], 10),
  //     buttonType
  //   };
  //   const checkIdEndTimeIsInTheFuture = isFutureDate(editAuctionTableData[0].end_time)
  //  if(checkIdEndTimeIsInTheFuture){
  //   await apiUpdateAuction(auctionObject);
  //  }else {
  //   dispatch(
  //     snackBarSlice.setSnackBar({
  //       type: "error",
  //       message: "error",
  //       timeout: 3000,
  //     })
  //   );
  //  }
  
  // };

  return (
    <EditAuctionDialogView
      closeEditAuctionModal={closeEditAuctionModal}
       editAuctiondialogOpen={editAuctiondialogOpen}
      auctionTime={auctionTime}
      handleDecrement={handleDecrement}
      handleIncrement={handleIncrement}
      arrangeBondAndSend={arrangeBondAndSend}
      editAuctionId={editAuctionId}
      editAuctionTableData={editAuctionTableData}
      setEditAuctionTableData={setEditAuctionTableData}
    />
  );
};

export default EditAuctionDialog;
