import React from "react";
import {
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { Grid } from "@mui/material";
import { useStyles } from "./ClientUser.style";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import { ReactComponent as EditIcon } from "../../../../assets/icons/edit.svg";
import SearchInput from "../../../ReusableComps/SearchInput/SearchInput";
import Loader from "../../../ReusableComps/loader/Loader";

const ClientUserInfoView = (props) => {
  const {
    openNewOrEditDialog,
    filterClientsUsers,
    sortedClientsUsers,
    downloadClientsUsersCsvFile,
    createNewClientUser,
  } = props;

  const tableHeaders = [
    "Name",
    "Client",
    "Email",
    "Phone",
    "Sales",
    "privileges",
  ];
  const classes = useStyles();
  return (
    <Grid item container style={{ padding: "16px" }}>
      <Grid
        container
        item
        xs={12}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={6}>
          <Button className={classes.button} onClick={createNewClientUser}>
            New
          </Button>
        </Grid>
        <Grid
          container
          item
          xs={6}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item style={{ paddingRight: "12px" }}>
            <IconButton
              className={classes.downloadButton}
              onClick={downloadClientsUsersCsvFile}
            >
              <DownloadIcon style={{ color: "#54A9FF" }} />
            </IconButton>
          </Grid>
          <Grid item>
            <SearchInput onChange={(e) => filterClientsUsers(e)} />
          </Grid>
        </Grid>
      </Grid>
      {/* // ! table place */}
      {sortedClientsUsers && sortedClientsUsers.length ? (
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <TableContainer
            style={{
              border: "1px solid #3D3F47",
              height: "calc(100vh - 210px)",
            }}
          >
            <Table style={{ width: "inherit" }} size="small" stickyHeader>
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => {
                    return (
                      <TableCell
                        key={index}
                        className={classes.headerTableRow}
                        style={{
                          textTransform:
                            header === "lei" ? "uppercase" : "capitalize",
                        }}
                      >
                        {header}
                      </TableCell>
                    );
                  })}
                  <TableCell className={classes.headerTableRow} />
                </TableRow>
              </TableHead>
              <TableBody className={classes.dataTableBody}>
                {sortedClientsUsers.map((client, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell className={classes.dataTableCell}>
                        {client.fullName === undefined
                          ? `${client?.firstName} ${client?.lastName}`
                          : client.fullName}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {client.clientName}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {client.email}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {`${client?.phone?.code} ${client?.phone?.number}`}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {client.traderName}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {client.privileges}
                      </TableCell>
                      <TableCell
                        className={classes.dataTableCell}
                        style={{ maxWidth: "1px" }}
                      >
                        <IconButton
                          size={"small"}
                          onClick={() =>
                            openNewOrEditDialog(client.clientUserId)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Grid
          item
          container
          justifyContent="center"
          style={{ position: "relative", top: "340px" }}
        >
          <Grid item>
            <Loader />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default ClientUserInfoView;
