import React from "react";
import {
  Grid,
  Typography,
  FormControl,
  Select,
  InputAdornment,
  MenuItem,
  IconButton,
  OutlinedInput,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import * as userSlice from "../../../store/slice/userSlice";
import { useStyles } from "./AdminsViewAsSelector.style";
import { useSelector } from "react-redux";
import RegionSelect from "../../ReusableComps/RegionSelect/RegionSelect";

const AdminsViewAsSelectorView = (props) => {
  const classes = useStyles();
  const { handleViewAsChange, clearImpersonation } = props;
  const { clientUsers } = useSelector((state) => state.userSlice);
  const { viewAsClientUser } = useSelector((state) => state.impersonationSlice);
  let under1280 = useMediaQuery("(max-width: 1280px)");

  return (
    <div style={{ position: "fixed", top: 10, right: under1280 ? 30 : 2 }}>
      <Grid container style={{ width: "fit-content" }} f>
        {viewAsClientUser !== null && under1280 && <RegionSelect />}

        <Grid item xs={under1280 ? 6 : 9}>
          <FormControl sx={{ maxWidth: 251, minWidth: 200 }} size="small">
            <InputLabel
              style={{
                color: "#54a9ff",
              }}
            >
              View As
            </InputLabel>
            <Select
              value={viewAsClientUser || ""}
              label="View As"
              onChange={handleViewAsChange}
              input={
                <OutlinedInput
                  label="View As"
                  className={classes.selectOutline}
                />
              }
              endAdornment={
                viewAsClientUser ? (
                  <InputAdornment
                    position="end"
                    style={{ marginRight: "10px" }}
                  >
                    {viewAsClientUser && (
                      <IconButton onClick={clearImpersonation}>
                        <ClearIcon style={{ color: "#ffffff" }} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ) : null
              }
              MenuProps={{
                PaperProps: {
                  sx: {
                    color: "#ffffff !important",
                    bgcolor: "#2f2f2f",
                  },
                },
              }}
            >
              {clientUsers.map((clientUser, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={clientUser}
                    style={{ textTransform: "capitalize" }}
                  >
                    {clientUser.fullName}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminsViewAsSelectorView;
