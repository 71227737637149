import React, { useState, useEffect, useRef } from "react";
import WorkspaceAuctionRowView from "./WorkspaceAuctionRow.view";
import ApiAuction from "../../../../apis/auctionRequest";
import { useDispatch, useSelector } from "react-redux";
import ConfirmationDialog from "../Dialogs/ConfirmationDialog/ConfirmationDialog";
import * as snackBarSlice from "../../../../store/slice/snackbarSlice";
import ApiLogs from "../../../../apis/logRequest";
import moment from "moment";

const WorkspaceAuctionRow = (props) => {
  const {
    auction,
    index,
    auctions,
    setAuctions,
    setUserEnteredData,
    auctionsRef,
    auctionEnded,
    setAuctionEnded,
    openEditAuctionDialog,
    setLogs,
    setSortedLogs,
    searchTerm,
    setUserLogs,
    setUserSortedLogs,
    buySellInfo,
    setBuySellInfo,
    confirmDialogOpen,
    setConfirmDialogOpen,
    handleInputBlur
  } = props;
  const [formattedTimeLeft, setFormattedTimeLeft] = useState("");
  const token = sessionStorage.getItem("token");

  const { viewAsClientUser } = useSelector((state) => state.impersonationSlice);
  const intervalIdsRef = useRef({});
  let countdownInterval;
  const [auctionStatus, setAuctionStatus] = useState({});
  // const [auctionUpdated, setAuctionUpdated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorCopyEl, setAnchorCopyEl] = useState(null);
  const { clientUsers } = useSelector((state) => state.userSlice);
  const permission = useSelector((state) => state.userSlice.permission);
  const [hoveredItem, setHoveredItem] = useState(null);
  const dispatch = useDispatch();

  const handleInputBuySellChanges = (
    e,
    agreement,
    auctionId,
    index,
    auctionStaticId,
    bondName,
    currency,
    auctionPrice,
    isin,
    oldBid,
    copyBid
  ) => {
    let value = e.target.value;
    if (value.includes(".")) {
      dispatch(
        snackBarSlice.setSnackBar({
          type: "warning",
          message: `Please enter size without decimals`,
        })
      );
      e.target.value = "";
    } else if (/\D/.test(value)) {
      dispatch(
        snackBarSlice.setSnackBar({
          type: "warning",
          message: `Invalid Bid ${value}`,
        })
      );
      e.target.value = "";
    }

  
    
    function updateAuction(item) {
      if (
        item.auction_id === auctionId &&
        item.auctionStaticId === auctionStaticId
      ) {
        if (agreement === "buy") {
          return {
            ...item,
            buy_size: e.target.value,
            bondName: bondName,
          };
        } else {
          return {
            ...item,
            sell_size: e.target.value,
            bondName: bondName,
          };
        }
      }
      return item;
    }

    const updatedResult = auctions.map((auction) => ({
      ...auction,
      items: auction.items.map(updateAuction),
    }));

    
    setBuySellInfo({
      auctionId: auctionId,
      agreement: agreement,
      price:e.target.value,
      auctionStaticId: auctionStaticId,
      bondName: bondName,
      cancelAll: false,
      currency: currency,
      auctionPrice: auctionPrice,
      isin: isin,
      oldBuyOrSell:copyBid
    });
    setAuctions(updatedResult)
  };

  const handleBuySellChangesKeyDown = async (e, name, auctionId, index,auctionStaticId,oldBid,copyBuySize) => {
  
    if (e.key === "Enter") {
      
      if(buySellInfo === ""){
        return
      }
      if(buySellInfo.price !== ""){
      e.target.blur();
      setBuySellInfo({...buySellInfo,keyType:e.key})
      setAuctions(auctions)
      setConfirmDialogOpen(true);
      }else{
  
        if(buySellInfo.oldBuyOrSell === undefined || buySellInfo.oldBuyOrSell === "" || buySellInfo.oldBuyOrSell === null || buySellInfo.oldBuyOrSell === "0"){
          return 
        }
      }
      e.target.blur();
      setBuySellInfo({...buySellInfo,keyType:e.key})
      setAuctions(auctions)
      setConfirmDialogOpen(true);
    }   
  };

  const getValidBuySizeDetails = (data, bidStatus) => {
 
    if (bidStatus === "buy") {
      const validBuySizeObjects = data.filter((item) => {
        const buySize = item.buy_size;
        return (
          buySize !== "0" &&
          buySize !== 0 &&
          buySize !== "" &&
          buySize !== undefined &&
          buySize !== null && item.confirmedBuy === true
        );
      });

      const buySizeDetails = validBuySizeObjects.map((item) => ({
        bondName: item.bond_name,
        currency: item.currency,
        buySize: item.buy_size,
        auctionPrice:item.auction_price,
        auctionStaticId:item.auctionStaticId
      }));

      return buySizeDetails;
    } else if (bidStatus === "sell") {
      const validSellSizeObjects = data.filter((item) => {
        const sellSize = item.sell_size;
        return (
          sellSize !== "0" &&
          sellSize !== 0 &&
          sellSize !== "" &&
          sellSize !== undefined &&
          sellSize !== null && item.confirmedSell === true
        );
      });

      const sellSizeDetails = validSellSizeObjects.map((item) => ({
        bondName: item.bond_name,
        currency: item.currency,
        sellSize: item.sell_size,
        auctionPrice:item.auction_price,
        auctionStaticId:item.auctionStaticId
      }));

      return sellSizeDetails;
    } else {
      const validSizeObjects = data.filter((item) => {
        const sellSize = item.sell_size;
        const buySize = item.buy_size;
        return (
          (sellSize !== "0" &&
            sellSize !== 0 &&
            sellSize !== "" &&
            sellSize !== undefined &&
            sellSize !== null && item.confirmedSell === true) ||
          (buySize !== "0" &&
            buySize !== 0 &&
            buySize !== "" &&
            buySize !== undefined &&
            buySize !== null && item.confirmedBuy === true)
        );
      });

      const sizeDetails = validSizeObjects.map((item) => ({
        
        bondName: item.bond_name,
        currency: item.currency,
        sellSize: item.sell_size === "0" ? null : item.sell_size,
        buySize: item.buy_size === "0" ? null : item.buy_size,
        auctionPrice:item.auction_price,
        auctionStaticId:item.auctionStaticId
      }));
      return sizeDetails;
    }
  };

  const handleTotalBuy = (buy) => {
    if (buy !== undefined) {
      if (buy.length > 0) {
        const filterBuy = buy.filter(
          (obj) => obj.buy_size !== "0" && obj.buy_size !== 0
        );
        const totalBuySize = filterBuy.reduce(
          (total, item) => total + Number(item.buy_size),
          0
        );
        const formattedTotal =
          totalBuySize === 0 ? "0" : totalBuySize.toString().replace(/^0+/, "");
        if (formattedTotal !== 0 && formattedTotal !== "0") {
          return formattedTotal;
        }
      }
    } else {
      return "";
    }
  };

  const handleTotalSell = (sell) => {
    if (sell !== undefined) {
      if (sell.length > 0) {
        const filterSell = sell.filter(
          (obj) => obj.sell_size !== "0" && obj.Sell_size !== 0
        );
        const totalSellSize = filterSell.reduce(
          (total, item) => total + Number(item.sell_size),
          0
        );
        const formattedTotal =
          totalSellSize === 0
            ? "0"
            : totalSellSize.toString().replace(/^0+/, "");
        if (formattedTotal !== 0 && formattedTotal !== "0") {
          return formattedTotal;
        }
      }
    } else {
      return "";
    }
  };

  const handleActiveInterests = (buy, sell) => {
    if (buy !== undefined && sell !== undefined) {
      const filterBuy = buy.filter(
        (obj) =>
          obj.buy_size !== "0" && obj.buy_size !== 0 && obj.buy_size !== null
      );
      const filterSell = sell.filter(
        (obj) =>
          obj.sell_size !== "0" && obj.sell_size !== 0 && obj.sell_size !== null
      );
      if (Number(filterBuy.length) !== 0 || Number(filterSell.length) !== 0) {
        return Number(filterBuy.length) + Number(filterSell.length);
      }
    }
    if (buy !== undefined && sell === undefined) {
      const filterBuy = buy.filter(
        (obj) =>
          obj.buy_size !== "0" && obj.buy_size !== 0 && obj.buy_size !== null
      );
      if (filterBuy.length > 0) {
        return filterBuy.length;
      }
    }
    if (sell !== undefined && buy === undefined) {
      const filterSell = sell.filter(
        (obj) =>
          obj.sell_size !== "0" && obj.sell_size !== 0 && obj.sell_size !== null
      );
      if (filterSell.length > 0) {
        return filterSell.length;
      }
    }
  };

  const handleBuyDisabled = (
    buy,
    sell,
    formattedTimeLeft,
    bondTradeExtraTime
  ) => {
    if (formattedTimeLeft !== "Extra time") {
      if (formattedTimeLeft?.includes("Time before start")) {
        return true;
      } else {
        if (buy === undefined && sell === undefined) {
          return false;
        } else {
          if (
            sell === undefined ||
            sell === "" ||
            sell === null ||
            sell === "null" ||
            sell === "0"
          ) {
            return false;
          }
          if (sell !== undefined || sell !== null || sell !== "") {
            return true;
          }
        }
      }
    } else {
      if (bondTradeExtraTime !== undefined) {
        const endTime = new Date(bondTradeExtraTime).getTime();
        const currentTime = new Date().getTime();
        const timeDifference = endTime - currentTime;

        if (timeDifference > 0) {
          if (buy === undefined && sell === undefined) {
            return false;
          } else {
            if (
              sell === undefined ||
              sell === "" ||
              sell === null ||
              sell === "null" ||
              sell === "0"
            ) {
              return false;
            }
            if (sell !== undefined || sell !== null || sell !== "") {
              return true;
            }
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  const handleSellDisabled = (
    buy,
    sell,
    formattedTimeLeft,
    bondTradeExtraTime
  ) => {
    if (formattedTimeLeft !== "Extra time") {
      if (formattedTimeLeft?.includes("Time before start")) {
        return true;
      } else {
        if (buy === undefined && sell === undefined) {
          return false;
        } else {
          if (
            buy === undefined ||
            buy === "" ||
            buy === null ||
            buy === "null" ||
            buy === "0"
          ) {
            return false;
          }
          if (buy !== undefined || buy !== null || buy !== "") {
            return true;
          }
        }
      }
    } else {
      if (bondTradeExtraTime !== undefined) {
        const endTime = new Date(bondTradeExtraTime).getTime();
        const currentTime = new Date().getTime();
        const timeDifference = endTime - currentTime;
        if (timeDifference > 0) {
          if (buy === undefined && sell === undefined) {
            return false;
          } else {
            if (
              buy === undefined ||
              buy === "" ||
              buy === null ||
              buy === "null" ||
              buy === "0"
            ) {
              return false;
            }
            if (buy !== undefined || buy !== null || buy !== "") {
              return true;
            }
          }
        } else {
          return true;
        }
      } else {
        return true;
      }
    }
  };

  const handleTableCellClassStyled = (
    isTraded,
    isVisible,
    bondTradeExtraTime,
    formattedTimeLeft,
    isBlind
  ) => {
    if (formattedTimeLeft !== "Extra time") {
     
      if (isTraded) {
        return "tradedBonds";
      } else if (isBlind === 0) {
        return "dataTableBody";
      } else {
        return "blindAuctionClass";
      }
    } else {
      if (bondTradeExtraTime !== undefined) {
        const endTime = new Date(bondTradeExtraTime).getTime();
        const currentTime = new Date().getTime();
        const timeDifference = endTime - currentTime;

        if (timeDifference > 0) {
          if (isTraded) {
            return "tradedBonds";
          } else {
            return "";
          }
        } else {
          return "blurredCell";
        }
      } else {
        return "blurredCell";
      }
    }
  };

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCopyButtonClick = (event) => {
    setAnchorCopyEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCopyMenuClose = () => {
    setAnchorCopyEl(null);
  };

  const handleMenuItemClick = async (
    value,
    auctionId,
    cancelStatus,
    auction,
    auctionStaticId,
    bondName,
    currency,
    oldBid,
    auctionPrice
  ) => {
    if (cancelStatus === "cancel all") {
      if (value === "cancel buy") {
        setBuySellInfo({
          auctionId: auctionId,
          agreement: "cancel buy",
          price: "0",
          auctionStaticId: "",
          bondName: "",
          cancelAll: true,
        });
       
      } else if (value === "cancel sell") {  
        setBuySellInfo({
          auctionId: auctionId,
          agreement: "cancel sell",
          price: "0",
          auctionStaticId: "",
          bondName: "",
          cancelAll: true,
        });
       
      } else {
        setBuySellInfo({
          auctionId: auctionId,
          agreement: "cancel all",
          price: "0",
          auctionStaticId: "",
          bondName: "",
          cancelAll: true,
        });

      }

      handleMenuClose();
      setConfirmDialogOpen(true);
    } else {
      const confirmedStatus = findConfirmedStatus(auctions, auctionStaticId);
      if (value === "buy") {      
         if(confirmedStatus.confirmedBuy === true){
          setBuySellInfo({
          auctionId: auctionId,
          agreement: "buy",
          price: "0",
          auctionStaticId: auctionStaticId,
          // bondName: "",
          cancelAll: false,
          bondName: bondName,
          currency: currency,
          oldBid: oldBid,
          auctionPrice
        });
        
      handleMenuClose();
      setConfirmDialogOpen(true);
      }
      } else {
        if(confirmedStatus.confirmedSell === true){
        setBuySellInfo({
          auctionId: auctionId,
          agreement: "sell",
          price: "0",
          auctionStaticId: auctionStaticId,
          // bondName: "",
          cancelAll: false,
          bondName: bondName,
          currency: currency,
          oldBid: oldBid,
          auctionPrice
        });
        
        handleMenuClose();
        setConfirmDialogOpen(true)
        }
       }
      }
  }

 const findConfirmedStatus = (auctions, targetAuctionStaticId) =>{
    for (let auction of auctions) {
        for (let item of auction.items) {
            if (item.auctionStaticId === targetAuctionStaticId) {
                return {
                    confirmedBuy: item.confirmedBuy,
                    confirmedSell: item.confirmedSell,
                    copyBuySize:item.copy_buy_size,
                    copySellSize:item.copy_sell_size,
                };
            }
        }
    }
    return null; 
 }

  const handleCellHover = (item) => {
    let buySizeUsers = [];
    let sellSizeUsers = [];
    if (
      item.client_user_id_buy !== undefined &&
      item.client_user_id_buy.length > 0
    ) {
      buySizeUsers = item.client_user_id_buy
        .map((item) => {
          const userWithFullName = clientUsers.find(
            (user) => user.clientUserId === item.client_user_id
          );
          return {
            ...item,
            clientFirstName: userWithFullName
              ? userWithFullName.clientFirstName
              : null,
          };
        })
        .filter(
          (item) =>
            item.buy_size !== 0 &&
            item.buy_size !== "0" &&
            item.buy_size !== null &&
            item.buy_size !== "null"
        );
    }
    if (
      item.client_user_id_sell !== undefined &&
      item.client_user_id_sell.length > 0
    ) {
      sellSizeUsers = item.client_user_id_sell
        ?.map((item) => {
          const userWithFullName = clientUsers.find(
            (user) => user.clientUserId === item.client_user_id
          );
          return {
            ...item,
            clientFirstName: userWithFullName
              ? userWithFullName.clientFirstName
              : null,
          };
        })
        .filter(
          (item) =>
            item.sell_size !== 0 &&
            item.sell_size !== "0" &&
            item.sell_size !== null &&
            item.sell_size !== "null"
        );
    }

    const combinedBuyAndSell = [...buySizeUsers, ...sellSizeUsers];

    setHoveredItem(combinedBuyAndSell);
  };

  const handleCopyMenuItemClick = (copyStatus, auctionId) => {
    if (copyStatus === "copy auction") {
      const targetAuction = auctions.find(
        (auction) => auction.auction_id === auctionId
      );
      if (targetAuction) {
        const regionName =
          targetAuction.items.length > 0
            ? `${targetAuction.items[0].name} - Auction`
            : "";
        const resultString =
          regionName +
          "\n" +
          "https://bond-x.makor-group.com" +
          "\n" +
          targetAuction.items
            .map(
              (item) =>
                `${item.bond_name} ${item.currency}  ${item.auction_price}`
            )
            .join("\n");
        navigator.clipboard.writeText(resultString);
      }
    } else {
      const targetAuction = auctions.find(
        (auction) => auction.auction_id === auctionId
      );
      let totalUsers;
      if (targetAuction) {
        totalUsers = targetAuction.items.reduce((sum, item) => {
          sum += item.client_user_id_buy ? item.client_user_id_buy.length : 0;
          sum += item.client_user_id_sell ? item.client_user_id_sell.length : 0;
          return sum;
        }, 0);
      }
      const originalNumber = totalUsers;
      const roundedNumber = roundToNearest5(originalNumber);
      const resultString =
        targetAuction.items[0].name +
        " " +
        roundedNumber +
        "+" +
        " " +
        "active interests on Auction " +
        "\n" +
        "https://bond-x.makor-group.com";
      navigator.clipboard.writeText(resultString);
    }
    handleCopyMenuClose();
  };

  const roundToNearest5 = (number) => {
    return Math.ceil(number / 5) * 5;
  };

  useEffect(() => {
    return () => {
      Object.values(intervalIdsRef.current).forEach((intervalId) => {
        clearInterval(intervalId);
      });
      intervalIdsRef.current = {};
    };
  }, []);

  const handleToggle = (id) => {
    setAuctions((prevState) =>
      prevState.map((auctions) =>
        auctions.auction_id === id
          ? { ...auctions, open: !auctions.open }
          : auctions
      )
    );
  };

  const closeConfirmModal = () => {
    setConfirmDialogOpen(false);
  };


  return (
    <>
      <WorkspaceAuctionRowView
        handleToggle={handleToggle}
        auction={auction}
        index={index}
        formattedTimeLeft={formattedTimeLeft}
        handleInputBuySellChanges={handleInputBuySellChanges}
        handleBuySellChangesKeyDown={handleBuySellChangesKeyDown}
        handleTotalBuy={handleTotalBuy}
        handleTotalSell={handleTotalSell}
        handleActiveInterests={handleActiveInterests}
        handleBuyDisabled={handleBuyDisabled}
        handleSellDisabled={handleSellDisabled}
        auctions={auctions}
        auctionsRef={auctionsRef}
        auctionEnded={auctionEnded}
        handleTableCellClassStyled={handleTableCellClassStyled}
        anchorEl={anchorEl}
        handleButtonClick={handleButtonClick}
        handleMenuClose={handleMenuClose}
        handleMenuItemClick={handleMenuItemClick}
        handleCellHover={handleCellHover}
        hoveredItem={hoveredItem}
        handleCopyButtonClick={handleCopyButtonClick}
        anchorCopyEl={anchorCopyEl}
        handleCopyMenuItemClick={handleCopyMenuItemClick}
        handleCopyMenuClose={handleCopyMenuClose}
        openEditAuctionDialog={openEditAuctionDialog}
        handleInputBlur={handleInputBlur}
        confirmDialogOpen={confirmDialogOpen}
    
      />
      {/* <ConfirmationDialog
        confirmDialogOpen={confirmDialogOpen}
        setConfirmDialogOpen={setConfirmDialogOpen}
        closeConfirmModal={closeConfirmModal}
        handleConfirmButtonClick={handleConfirmButtonClick}
        buySellInfo={buySellInfo}
      /> */}
    </>
  );
};

export default WorkspaceAuctionRow;
