import React, { useEffect, useState } from "react";
import ForgotPasswordView from "./ForgotPassword.view";
import Api from "../../../apis/userRequest";
import { useDispatch, useSelector } from "react-redux";
import * as authSlice from "../../../store/slice/authSlice";
import * as snackBarSlice from "../../../store/slice/snackbarSlice";
import { useNavigate } from "react-router-dom";
import ChangePassword from "../ChangePassword/ChangePassword";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const { token, tokenType } = useSelector((state) => state.authSlice);
  const [sixDigits, setSixDigits] = useState("");
  const [authSuccess, setAuthSuccess] = useState(false);

  const authForForgotPassword = async () => {
   
    const chosenWay = "email";
    const authType = "forgotPassword";
    try {
      let result = await Api.authRequest({ chosenWay, authType }, token);
      dispatch(authSlice.setChosenWay(chosenWay));
      dispatch(
        snackBarSlice.setSnackBar({
          type: "success",
          message: result.data.message,
          timeout: 3000,
        })
      );
    } catch (error) {
      dispatch(
        snackBarSlice.setSnackBar({
          type: "error",
          message: "error sending authentication",
          timeout: 3000,
        })
      );
    }
  };

  const handleOnClickVerifyCode = async () => {
    const authTypeRequest = "forgotPassword";
    try {
      let response;
      response = await Api.sixDigitAuthCheck(
        { sixDigits, authTypeRequest },
        token
      );
      if (response.data.token !== undefined && response.data.token !== null) {
        setAuthSuccess(true);
        // connectWS(response.data.token);
      }
    } catch (error) {
      console.log(error);
      dispatch(
        snackBarSlice.setSnackBar({
          type: "error",
          message: error.response.data.message,
          timeout: 3000,
        })
      );
    }
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("userData");
      dispatch(authSlice.clearUserData());
    };
  }, []);
  return !authSuccess ? (
    <ForgotPasswordView
      sixDigits={sixDigits}
      setSixDigits={setSixDigits}
      authForForgotPassword={authForForgotPassword}
      handleOnClickVerifyCode={handleOnClickVerifyCode}
    />
  ) : (
    <ChangePassword />
  );
};

export default ForgotPassword;
