import React, { useState } from "react";

import {
  Typography,
  Grid,
  Button,
  IconButton,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useStyles } from "./Workspace.style";
import { ReactComponent as Expand } from "../../../assets/icons/expand.svg";
import { ReactComponent as Minimize } from "../../../assets/icons/collapsAuction.svg";
import WorkspaceAuctionRow from "./WorkspaceAuctionRow/WorkspaceAuctionRow";
import { useSelector } from "react-redux";
import RegionSelect from "../../ReusableComps/RegionSelect/RegionSelect";
import SystemLogs from "../Reports/SystemLogs/SystemLogs";
import SearchInput from "../../ReusableComps/SearchInput/SearchInput";
import ConfirmationDialog from "./Dialogs/ConfirmationDialog/ConfirmationDialog";
import { ReactComponent as UserOnline } from "../../../assets/icons/userOnline.svg";
import { StyledTooltip } from "../../aside/Sidebar/Sidebar.style";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";

const WorkspaceView = (props) => {
  const {
    openNewAuctionDialog,
    auctions,
    setAuctions,
    auctionsTransaction,
    setAuctionsTransaction,
    apiGetAuctions,
    userEnteredData,
    setUserEnteredData,
    auctionsRef,
    auctionEnded,
    setAuctionEnded,
    openEditAuctionDialog,
    logs,
    sortedField,
    handleSorting,
    setSearchTerm,
    setLogs,
    cancelAll,
    getValidBuySizeDetails,
    setSortedLogs,
    searchTerm,
    setUserLogs,
    sortedUserLogs,
    setUserSortedLogs,
    buySellInfo,
    setBuySellInfo,
    confirmDialogOpen,
    setConfirmDialogOpen,
    handleConfirmButtonClick,
    closeConfirmModal,
    handleInputBlur,
    showActivity,
    setShowActivity,
    handleUsersButtonClick,
    isLoading,
    usersOnline,
  } = props;
  const permission = useSelector((state) => +state.userSlice.permission);
  const { viewAsClientUser } = useSelector((state) => state.impersonationSlice);
  const classes = useStyles();
  const [allAuctionOpen, setAllAuctionOpen] = useState(false);
  let under1280 = useMediaQuery("(max-width: 1280px)");
  const activeInterestsTableHeaders = ["Name"];

  // const systemLogsHeaders = [
  //   { field: "Action", flex:0.8,filter: true ,
  //   filterParams: {        
  //     maxNumConditions :1,
  //     suppressMenu:true   
  //    } },
  //   { field: "Bond", flex:1,filter: true ,
  //   filterParams: {        
  //     maxNumConditions :1    
  //    } },
  //   { field: "Auction price", flex:0.6,filter: true ,
  //   filterParams: {        
  //     maxNumConditions :1    
  //    }  },
  //   { field: "Quantity", flex:0.6,filter: true ,
  //   filterParams: {        
  //     maxNumConditions :1    
  //    }  },
  //   { field: "Date Time (UTC)", flex:0.9,filter: true ,
  //   filterParams: {        
  //     maxNumConditions :1    
  //    }  },
  //   { field: "User", flex:0.6,filter: true ,
  //   filterParams: {        
  //     maxNumConditions :1    
  //    }  },
  //   { field: "On Behalf", flex:0.6,filter: true ,
  //   filterParams: {        
  //     maxNumConditions :1    
  //    }  },
  // ];
  const systemLogsHeaders = [
    "Action",
     "Bond",
     "Auction price",
    "Quantity",
    "Date Time (UTC)",
    "User",
    "On Behalf",
  ]
  const expandAll = () => {
    setAllAuctionOpen(!allAuctionOpen);
    setAuctions((prevState) =>
      prevState.map((auctions) => {
        return { ...auctions, open: allAuctionOpen };
      })
    );
  };

  const tableContent = (
    <TableContainer>
      <Table className={classes.userAuctionTable}>
        <TableHead>
          <TableRow style={{ height: "-6px" }}>
            {activeInterestsTableHeaders.map((header, index) => {
              return (
                <TableCell
                  key={index}
                  auction={index}
                  className={classes.userOnlineHeaderTableRow}
                >
                  {header}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody className={classes.userOnlineDataTableBody}>
          {usersOnline?.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell className={classes.userOnlineDataTableCell}>
                  {item.name}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <Grid container className={classes.mainWorkspaceContainer}>
      <Grid
        item
        container
        alignContent="center"
        className={classes.auctionTitleGrid}
      >
        <Grid item xs={under1280 ? 6 : 4}>
          <Typography className={classes.title}>
            <span className={classes.makorSpan}>MAKOR </span>
            <span style={{ color: "#54A9FF" }}>Bond X</span> Workspace
            {(permission === 1 || permission === "1") ? 
            <Tooltip title={tableContent} onMouseEnter={handleUsersButtonClick}>
              <span>
                <IconButton>
                  <UserOnline />
                </IconButton>
              </span>
            </Tooltip>
             :""}
          </Typography>
        </Grid>
        {under1280 ? (
          // ((permission === 1 || permission === "1") &&
          //   viewAsClientUser !== null) ||
          permission === 2 ? (
            <RegionSelect
              regionMainClass={"regionSelectForSmall"}
              formControlWidth={"100%"}
            />
          ) : null
        ) : null}
      </Grid>
      <Grid
        item
        container
        xs={12}
        style={{
          minHeight: "calc(100vh - 61px)",
          maxHeight: "calc(100vh - 61px)",
        }}
        justifyContent={"space-between"}
      >
        <Grid item className={classes.auctionGrid}>
          <Grid
            item
            container
            justifyContent="space-between"
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "#171717",
            }}
          >
            {((under1280 && permission === 2) || permission === "2") && (
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ paddingBottom: "10px" }}
                >
                  <Grid item xs={6}>
                    <Typography className={classes.systemLogString}>
                      Activity
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="flex-end" xs={6}>
                    <IconButton
                      size="small"
                      onClick={() => setShowActivity(!showActivity)}
                      style={{
                        width: "32px",
                        height: "32px",
                        background: "#2D2D2D",
                        borderRadius: "4px",
                      }}
                    >
                      {showActivity ? <Minimize /> : <Expand />}
                    </IconButton>
                  </Grid>
                </Grid>
                {showActivity && (
                  <Grid
                    style={{
                      backgroundColor: "#222222",
                      border:
                        showActivity !== null ? null : "1px solid #3D3F47",
                    }}
                  >
                    <SystemLogs
                      // fullView={fullView}
                      headers={systemLogsHeaders}
                      sortedField={sortedField}
                      tableData={
                        (permission === 1 || permission === "1") &&
                        viewAsClientUser === null
                          ? logs
                          : sortedUserLogs
                      }
                      handleSorting={handleSorting}
            
                      component={"systemLog"}
                      tableContainerSx={{
                        maxHeight: "150px",
                        overflow: "auto",
                      }}
                      permission={(permission === 1 || permission === "1") &&
                        viewAsClientUser === null ? "trader" : "client"}
                    />
                  </Grid>
                )}
              </Grid>
            )}
            <Grid item xs={6} style={{ display: "flex" }}>
              <Typography className={classes.auction}>Auctions</Typography>
            </Grid>
            <Grid
              item
              // xs={under1280 ? 12 : 6}
              xs={6}
              container
              style={{ marginBottom: "10px" }}
              justifyContent="flex-end"
              alignItems="center"
            >
              {auctions && auctions?.length > 1 ? (
                <Grid
                  item
                  style={{
                    marginRight:
                      ((permission === 1 || permission === "1") &&
                        viewAsClientUser === null) ||
                      getValidBuySizeDetails(auctions).length > 0
                        ? "10px"
                        : 0,
                  }}
                >
                  <IconButton
                    size="small"
                    onClick={() => expandAll()}
                    style={{
                      width: "32px",
                      height: "32px",
                      background: "#2D2D2D",
                      borderRadius: "4px",
                    }}
                  >
                    {allAuctionOpen ? <Expand /> : <Minimize />}
                  </IconButton>
                </Grid>
              ) : null}

              <Grid item>
                {(permission === 1 || permission === "1") &&
                viewAsClientUser === null ? (
                  <Button
                    onClick={openNewAuctionDialog}
                    className={classes.newAuctionButton}
                  >
                    New Auction
                  </Button>
                ) : getValidBuySizeDetails(auctions).length > 0 ? (
                  <Button
                    onClick={cancelAll}
                    className={classes.cancelAllButton}
                  >
                    Cancel All
                  </Button>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
          {auctions?.map((auction, index) => {
            return (
              <WorkspaceAuctionRow
                auctionsTransaction={auctionsTransaction}
                setAuctionsTransaction={setAuctionsTransaction}
                auction={auction}
                index={index}
                key={index}
                setAuctions={setAuctions}
                auctions={auctions}
                apiGetAuctions={apiGetAuctions}
                userEnteredData={userEnteredData}
                setUserEnteredData={setUserEnteredData}
                auctionsRef={auctionsRef}
                auctionEnded={auctionEnded}
                setAuctionEnded={setAuctionEnded}
                openEditAuctionDialog={openEditAuctionDialog}
                setLogs={setLogs}
                setSortedLogs={setSortedLogs}
                searchTerm={searchTerm}
                setUserLogs={setUserLogs}
                setUserSortedLogs={setUserSortedLogs}
                buySellInfo={buySellInfo}
                setBuySellInfo={setBuySellInfo}
                confirmDialogOpen={confirmDialogOpen}
                setConfirmDialogOpen={setConfirmDialogOpen}
                handleInputBlur={handleInputBlur}
              />
            );
          })}
        </Grid>
        <>
          {(permission === 1 || permission === "1") &&
          viewAsClientUser === null ? (
            <>
              <Grid item className={classes.systemLogsGrid}>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography className={classes.systemLogString}>
                      System Logs
                    </Typography>
                  </Grid>
                  <Grid item style={{ paddingBottom: "5px" }}>
                    <SearchInput
                      placeholder="Search Logs"
                      width="250px"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </Grid>
                </Grid>

                <Grid
                  style={{
                    backgroundColor: "#222222",
                    border: "1px solid #3D3F47",
                  }}
                >
                  <SystemLogs
                    headers={systemLogsHeaders}
                    sortedField={sortedField}
                    tableData={
                      (permission === 1 || permission === "1") &&
                      viewAsClientUser === null
                        ? logs
                        : sortedUserLogs
                    }
                    handleSorting={handleSorting}
                    component={"systemLog"}
                    tableContainerSx={{
                      maxHeight: "calc(100vh - 110px)",
                      minHeight: "calc(100vh - 110px)",
                      overflow: "auto",
                    }}
                    permission={(permission === 1 || permission === "1") &&
                      viewAsClientUser === null ? "trader" : "client"}
                  />
                </Grid>
              </Grid>
            </>
          ) : !under1280 ? (
            <>
              <Grid item className={classes.systemLogsGrid}>
                <Grid
                  item
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ paddingBottom: "7px" }}
                >
                  <Grid item>
                    <Typography className={classes.systemLogString}>
                      Activity
                    </Typography>
                  </Grid>
                  {/* Search and region Select */}
                  <Grid
                    item
                    container
                    className={classes.searchAndRegionSelectGrid}
                    justifyContent="flex-end"
                  >
                    <RegionSelect
                      regionMainClass={"regionSelect"}
                      formControlWidth="95%"
                    />
                    <Grid item>
                      <SearchInput
                        placeholder="Search Logs"
                        width="200px"
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  style={{
                    backgroundColor: "#222222",
                    border: "1px solid #3D3F47",
                  }}
                >
                  <SystemLogs
                    headers={systemLogsHeaders}
                    sortedField={sortedField}
                    tableData={
                      (permission === 1 || permission === "1") &&
                      viewAsClientUser === null
                        ? logs
                        : sortedUserLogs
                    }
                    handleSorting={handleSorting}
                    component={"systemLog"}
                    tableContainerSx={{
                      maxHeight:
                        (permission === 1 || permission === "1") &&
                        viewAsClientUser === null
                          ? "calc(100vh - 110px)"
                          : "calc(100vh - 120px)",
                      minHeight:
                        (permission === 1 || permission === "1") &&
                        viewAsClientUser === null
                          ? "calc(100vh - 110px)"
                          : "calc(100vh - 120px)",
                      overflow: "auto",
                    }}
                    permission={(permission === 1 || permission === "1") &&
                      viewAsClientUser === null ? "trader" : "client"}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {(permission === 1 || permission === "1") &&
              viewAsClientUser === null ? (
                <Button
                  onClick={() => openNewAuctionDialog()}
                  className={classes.newAuctionButton}
                >
                  New Auction
                </Button>
              ) : null}
            </>
          )}
        </>
        <ConfirmationDialog
          confirmDialogOpen={confirmDialogOpen}
          setConfirmDialogOpen={setConfirmDialogOpen}
          closeConfirmModal={closeConfirmModal}
          handleConfirmButtonClick={handleConfirmButtonClick}
          buySellInfo={buySellInfo}
        />
      </Grid>
    </Grid>
  );
};

export default WorkspaceView;
