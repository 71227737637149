import { Typography, Grid, Tabs } from "@mui/material";
import React from "react";
import { useStyles } from "./Reports.style";
import {
  StyledTab,
  StyledTabPanel,
} from "../../ReusableComps/Tabs/TabsAndTabsPanel";
import { TabContext } from "@mui/lab";
import "../../../App.css";

import TradesReports from "./TradesReports/TradesReports";
import ActivityReport from "./ActivityReports/ActivityReports";
function ReportsView(props) {
  const { handleTabChange, currentTab } = props;
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.mainReportsContainer}>
      <Grid item style={{ paddingBottom: "10px" }}>
        <Typography className={classes.title}>Reports</Typography>
      </Grid>
      <Grid item container>
        <TabContext value={currentTab}>
          <Grid item container>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
            >
              <StyledTab label="trades" value="trades" />
              <StyledTab label="activity" value="activity" />
            </Tabs>
          </Grid>
          <StyledTabPanel value="trades">
            <TradesReports type="trades" />
          </StyledTabPanel>
          <StyledTabPanel value="activity">
            <ActivityReport />
          </StyledTabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
}

export default ReportsView;
