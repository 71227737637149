import React from "react";
import {
  Dialog,
  Grid,
  Typography,
  IconButton,
  Button,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
} from "@mui/material";
import { useStyles } from "./EditAuctionDialog.style";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as UploadFile } from "../../../../../assets/icons/upload-file.svg";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ButtonGroup from "@mui/material/ButtonGroup";
import clsx from "clsx";

const EditAuctionDialogView = (props) => {
  const classes = useStyles();

  const {
    closeEditAuctionModal,
    editAuctiondialogOpen,
    auctionTime,
    handleDecrement,
    handleIncrement,
    arrangeBondAndSend,
    editAuctionTableData
  } = props;
  const tableHeaders = [
    "Region",
    "ISIN",
    "Bond Name",
    "Currency",
    "Last Price",
    "Auction Price",
  ];
  return (
    <Dialog
      open={editAuctiondialogOpen}
      classes={{ paper: classes.dialog }}
      onClose={closeEditAuctionModal}
      
    >
      <Grid container style={{ padding: "28px 48px" }}>
        <Grid
          item
          xs={12}
          container
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Typography className={classes.header}>Update Auction</Typography>
          </Grid>
          <Grid item>
            <IconButton size="small" onClick={closeEditAuctionModal}>
              <CloseIcon style={{ color: "#ffffff" }} />
            </IconButton>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent="flex-end"
          style={{ paddingTop: "20px" }}
        >
          <ButtonGroup>
            <Button
              className={clsx(
                editAuctionTableData !== undefined && editAuctionTableData.length > 0 ? editAuctionTableData[0].is_blind === 1 ? classes.selectedBtn : classes.buttonGroupBtn:""
              )}
            >
              Blind
            </Button>
            <Button
              className={clsx(
                editAuctionTableData !== undefined && editAuctionTableData.length > 0 ? editAuctionTableData[0].is_blind === 0 ? classes.selectedBtn : classes.buttonGroupBtn:""
              )}
            >
              Visible
            </Button>
          </ButtonGroup>
          <Grid item>
          </Grid>
        </Grid>
        <Grid item container style={{ paddingTop: "60px" }}>
          <TableContainer
            style={{ border: "1px solid #3D3F47" , height: "505px"}}
          >
            <Table stickyHeader style={{ width: "inherit" }} size="small">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((header, index) => {
                    return (
                      <TableCell key={index} className={classes.headerTableRow}>
                        {header}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody className={classes.dataTableBody}>
                {editAuctionTableData?.map((bondItem, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.dataTableCell}
                        style={{
                          alignContent: "center",
                          textTransform: "capitalize",
                        }}
                      >
                        {bondItem.name}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                      {bondItem.isin}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {bondItem.bond_name}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {bondItem.currency}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        {bondItem.last_price}
                      </TableCell>
                      <TableCell className={classes.dataTableCell}>
                        <TextField
                          type="text"
                          value={
                            bondItem.auction_price !== "" && bondItem.auction_price
                          }
                        
                          className={classes.auctionPriceTextField}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid
          item
          container
          justifyContent="space-between"
          style={{ paddingTop: "60px" }}
        >
          <Grid
            item
            xs={4}
            container
            alignItems="center"
            justifyContent="space-between"
          >
             <Grid item xs={4}>
              <Typography>Auction Time:</Typography>
            </Grid>
            <Grid item>
              <Grid
                item
                container
                alignItems="center"
                style={{ paddingRight: "31px" }}
              >
                <Grid item>
                  <IconButton onClick={handleDecrement}>
                    <RemoveIcon style={{ color: "#2692ff" }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <TextField
                    value={auctionTime}
                    className={classes.auctionTimeTextField}
                    readOnly
                  />
                </Grid>
                <Grid item>
                  <IconButton onClick={handleIncrement}>
                    <AddIcon style={{ color: "#2692ff" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} container justifyContent="space-between">
            <Grid item>
              <Button
                className={classes.startInButton}
                onClick={() => arrangeBondAndSend(true)}
              >
               End Now
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.startNowButton}
                onClick={() => arrangeBondAndSend(false)}
              >
                Update
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>


      
    </Dialog>
  );
};

export default EditAuctionDialogView;
