import { Button, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useStyles } from "./ForgotPassword.styles";
import { useSelector } from "react-redux";
import AuthSelectorView from "../Login/AuthSelector/AuthSelector.view";

const ForgotPasswordView = (props) => {
  const {
    authForForgotPassword,
    sixDigits,
    setSixDigits,
    handleOnClickVerifyCode,
  } = props;
  const classes = useStyles();
  const { userEmail, chosenWay } = useSelector((state) => state.authSlice);

 
  const resetPasswordView = () => {
    return (
      <Paper
        className={classes.forgotPasswordPaper}
        elevation={0}
        style={{ height: 220 }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Typography
              style={{
                color: "#ffffff",
                fontSize: "1.5rem",
                textAlign: "center",
              }}
            >
              You're just a step away from resetting your password.
              <br /> For your security, we'll need to verify your identity using
              Email authentication.
              <br />
              the Email will send to {userEmail}
            </Typography>
          </Grid>
          <Grid item style={{ paddingTop: "35px" }}>
            <Button
              className={classes.authButton}
              onClick={authForForgotPassword}
            >
              Authenticate
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: "100vh" }}
    >
      {!chosenWay ? (
        resetPasswordView()
      ) : (
        <AuthSelectorView
          handleOnClickVerifyCode={handleOnClickVerifyCode}
          sixDigits={sixDigits}
          setSixDigits={setSixDigits}
        />
      )}
    </Grid>
  );
};

export default ForgotPasswordView;
