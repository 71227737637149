import React from "react";
import { Grid, IconButton, InputBase } from "@mui/material";
import { useSelector } from "react-redux";
import { useStyles } from "../Reports.style";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import ReportsTable from "../ReportsTable/ReportsTable";
import Loader from "../../../ReusableComps/loader/Loader";
import SearchInput from "../../../ReusableComps/SearchInput/SearchInput";

const tradeTableHeaders = [
  { field: "Trade Date",filter: true,filter: "agDateColumnFilter",
  filterParams: {        
    maxNumConditions :1,
    suppressHeaderFilterButton :true,
    comparator: function (filterLocalDateAtMidnight, cellValue) {
      const cellDateParts = cellValue.split(' ');
      const cellDate = new Date(cellDateParts[0]);

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    parseDate: function (data) {
      // parse '12-May-2024 13:24:18' into a Date object
      const parts = data.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseMonth(parts[1]);
      const year = parseInt(parts[2].split(' ')[0], 10);
      const time = parts[2].split(' ')[1];
      const [hours, minutes, seconds] = time.split(':').map(Number);
      return new Date(year, month, day, hours, minutes, seconds);
    
  },
   }},
  { field: "ISIN", flex: 2,filter: true ,
  filterParams: {        
    maxNumConditions :1    
   } },
  { field: "Instrument Name", flex: 2,filter: true ,
  filterParams: {        
    maxNumConditions :1    
   } },
  { field: "Currency", flex: 2,filter: true ,
  filterParams: {       
    maxNumConditions :1    
   }},
  { field: "Side", flex: 2,filter: true ,
  filterParams: {          
  maxNumConditions :1    
  }},
  { field: "Execution Time (UTC)", flex: 2,filter: true ,
  filterParams: {         
    maxNumConditions :1    
   } },
  { field: "Quantity", flex: 2,filter: true ,
  filterParams: {         
    maxNumConditions :1    
   } },
  { field: "Price", flex: 2,filter: true ,
  filterParams: {         
    maxNumConditions :1    
   } },
  { field: "Client Name", flex: 2,filter: true ,
  filterParams: {          
    maxNumConditions :1    
   } },
  { field: "Trader Name", flex: 2,filter: true ,
  filterParams: {        
    maxNumConditions :1    
   } },
  { field: "Makor Sales", flex: 2,filter: true ,
  filterParams: {        
    maxNumConditions :1    
   } },
];

const parseMonth = (monthStr) => {
  const months = [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ];
  return months.indexOf(monthStr);
}

function TradesReportsView(props) {
  const {
    reports,
    onDownloadReportsToCsv,
    dateSetter,
    handleSorting,
    setSearchTerm,
    reportsLoading,
    sortedField,
  } = props;
  const classes = useStyles();
  const permission = useSelector((state) => +state.userSlice.permission);

  return (
    <Grid container direction="column" style={{ padding: "10px" }}>
      <Grid
        item
        container
        justifyContent="flex-end"
        alignItems="center"
        style={{
          paddingTop: permission === 1 || permission === "1" ? "20px" : 0,
        }}
      >
        <Grid item style={{ paddingRight: "12px" }}>
          <SearchInput
            width="250px"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Grid>
        <Grid item>
          <InputBase
            id="dateInputBase"
            name="date"
            variant="outlined"
            type="date"
            className={classes.date}
            placeholder="dd/mm/yyyy"
            onChange={(e) => dateSetter(e)}
          />
        </Grid>
        <Grid item>
          <IconButton
            className={classes.downloadButton}
            onClick={onDownloadReportsToCsv}
          >
            <DownloadIcon style={{ color: "#54A9FF" }} />
          </IconButton>
        </Grid>
      </Grid>
        <Grid item container style={{ paddingTop: "10px" }}>
          <ReportsTable
            headers={tradeTableHeaders}
            sortedField={sortedField}
            tableData={reports}
            handleSorting={handleSorting}
          />
        </Grid>
    </Grid>
  );
}

export default TradesReportsView;
