import { makeStyles, styled } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    marginTop: "18px !important",
    textAlign: "left !important",
    fontSize: "2.1253rem !important",
    color: "#FFFFFF !important",
    opacity: "1",
  },
}));


