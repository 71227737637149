import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ApiRegion from "../../../apis/regionRequest";
import ApiUser from "../../../apis/userRequest";
import { useLocation, useNavigate } from "react-router-dom";
import * as regionSlice from "../../../store/slice/regionSlice";
import AdminsViewAsSelectorView from "./AdminsViewAsSelector.view";
import * as impersonationSlice from "../../../store/slice/impersonationSlice";
import auctionRequest from "../../../apis/auctionRequest";

const AdminsViewAsSelector = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const token = sessionStorage.getItem("token");

  const getClientUserRegions = async (viewAsClientUserId) => {
    try {
      const clientUserRegions = await ApiRegion.getClientUserRegions(
        token,
        viewAsClientUserId
      );
      if (clientUserRegions.data) {
        let regionSorted = clientUserRegions.data?.sort((a, b) => a.region_name.localeCompare(b.region_name))
        dispatch(regionSlice.updateRegionsList(regionSorted));
        // dispatch(regionSlice.updateRegionsList(clientUserRegions.data));
      }
    } catch (error) {
      console.log(error);
    }
  };


  const handleViewAsChange = async (e) => {
    dispatch(impersonationSlice.setImpersonation(e.target.value));
    try {
     let response =  await ApiUser.impersonationAuth(token, e.target.value);
      if(response.data === "impersonation created"){
       dispatch(impersonationSlice.setImpersonationCreated(true))
      }
    } catch (error) {
      console.log(error);
    }
    if (!e.target.value.privileges.includes("trading")) {
      if (location.pathname === "/workspace") {
        navigate("/reports");
      }
    } else {
      getClientUserRegions(e.target.value.clientUserId);
    }
  };

  const clearImpersonation = async () => {
    dispatch(impersonationSlice.clearImpersonation());
    dispatch(regionSlice.clearRegionList());
    if (location.pathname !== "/workspace") {
      navigate("/workspace");
    }
    const token = sessionStorage.getItem("token");
    try {
      await auctionRequest.removeAuctionsViewAs(token);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 116) {
    
        clearImpersonation();
      }
    };
    const handleBeforeUnload = (event) => {
      if (event.currentTarget.performance.navigation.type === 1) {
        clearImpersonation();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <AdminsViewAsSelectorView
      handleViewAsChange={handleViewAsChange}
      clearImpersonation={clearImpersonation}
    />
  );
};

export default AdminsViewAsSelector;
