import React from "react";
import { makeStyles, styled } from "@mui/styles";
import { TableCell } from "@mui/material";
export const useStyles = makeStyles((theme) => ({
  editButton: {
    backgroundColor: "#FF9E54",
    textTransform: "capitalize !important",
    borderRadius: "4px",
    width: "122px",
    height: "32px",
    color: "#000000 !important",
    fontSize: "1.167rem",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#cc7e43",
    },
  },
  downloadButton: {
    width: "32px",
    height: "32px",
    background: "#24414B 0% 0% no-repeat padding-box !important",
    border: "1px solid #54A9FF !important",
    borderRadius: "4px",
    opacity: 1,
  },
  headerTableRow: {
    fontSize: "1.08rem !important",
    color: "#5a5c62 !important",
    textAlign: "center !important",
    borderBottom: "1px solid #000000 !important",
    fontWeight: "600",
    "&.MuiTableCell-stickyHeader": {
      backgroundColor: "#2d2d2d",
    },
  },
  dataTableBody: {
    "& tr:nth-of-type(odd)": {
      backgroundColor: "#222222 !important",
    },
    "& tr:nth-of-type(even)": {
      backgroundColor: "#2d2d2d !important",
    },
  },
  dataTableCell: {
    fontSize: "14px",
    color: "#ffffff !important",
    textAlign: "center !important",
    borderBottom: "none",
  },
  tableStyle: {
    "&.MuiTableHead-root": { background: "red" },
  },
}));
export const StyledTableCell = styled((props) => <TableCell {...props} />)({
  // overflow: "hidden",
  textOverflow: "ellipsis",
  // whiteSpace: "noWrap",
  textAlign: "center",
  paddingBlock: 1,
  paddingInline: 5,
  color: "#ffffff !important",
  borderBottom: "1px solid #b3b3b3",
  "& .MuiTableCell-stickyHeader": {
    backgroundColor: "#000000",
  },
  fontSize: "1rem",
});
