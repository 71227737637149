import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ChangePasswordView from "./ChangePassword.view";
import { passwordValidation, setConfig } from "enigma-x-utilities";
import Api from "../../../apis/userRequest";
import * as snackBarSlice from "../../../store/slice/snackbarSlice";
import * as authSlice from "../../../store/slice/authSlice";

function ChangePassword() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.authSlice);
  const userEntrance = useSelector((state) => state.userSlice.entrance);
  const [formState, setFormState] = useState({
    password: "",
    passwordCopy: "",
    showNewPassword: false,
    showConfirmedPassword: false,
  });
  const [equalPasswords, setEqualPasswords] = useState(null);
  const [validState, setValidState] = useState({
    validation: [{}, {}, {}, {}, {}],
  });

  const handleClickShowPassword = (kind) => {
    if (kind === "new") {
      setFormState({
        ...formState,
        showNewPassword: !formState.showNewPassword,
      });
    }
    if (kind === "confirmed") {
      setFormState({
        ...formState,
        showConfirmedPassword: !formState.showConfirmedPassword,
      });
    }
  };

  const valid = (e) => {
    if (e.target.value === "") {
      setEqualPasswords(null);
    } else {
      if (e.target.value === formState.passwordCopy) {
        setEqualPasswords(true);
      } else {
        setEqualPasswords(false);
      }
    }
    setFormState({
      ...formState,
      password: e.target.value,
    });
    setConfig("password", {
      characterLen: 9,
      upperCase: 1,
      lowerCase: 1,
      num: 1,
      symbol: "!@#^",
    });
    setValidState(passwordValidation(e.target.value));
  };

  const handlePasswordCopy = (e) => {
    setFormState({ ...formState, passwordCopy: e.target.value });
    if (formState.passwordCopy === "") {
      setEqualPasswords(null);
    } else {
      if (e.target.value === formState.password) {
        setEqualPasswords(true);
      } else {
        setEqualPasswords(false);
      }
    }
  };

  const saveNewPassword = async () => {
    let updatePassword;
    try {
      if (userEntrance !== null && userEntrance === 0) {
        updatePassword = await Api.resetPassword(
          { newPassword: formState.password, userEntrance: userEntrance },
          token
        );
      } else {
        updatePassword = await Api.resetPassword(
          { newPassword: formState.password },
          token
        );
      }

      if (updatePassword.status === 200) {
        dispatch(
          snackBarSlice.setSnackBar({
            type: "success",
            message: updatePassword.data.message,
            timeout: 3000,
          })
        );
        sessionStorage.removeItem("token");
        sessionStorage.removeItem("userData");
        dispatch(authSlice.clearUserData());
        navigate("/");
      }
    } catch (error) {dispatch(
      snackBarSlice.setSnackBar({
        type: "error",
        message: "Error resetting password",
        timeout: 3000,
      })
    );}
  };
  const logout = () => {
    navigate("/");
  };

  return (
    <ChangePasswordView
      valid={valid}
      logout={logout}
      formState={formState}
      validState={validState}
      equalPasswords={equalPasswords}
      saveNewPassword={saveNewPassword}
      handlePasswordCopy={handlePasswordCopy}
      handleClickShowPassword={handleClickShowPassword}
    />
  );
}

export default ChangePassword;
