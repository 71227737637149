import React, { useState } from "react";
import {
  Dialog,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import { useStyles } from "./ConfirmationDialog.style";


const ConfirmationDialogView = (props) => {
  const classes = useStyles();
  const [lastEnterPressTime, setLastEnterPressTime] = useState(0);

  const {
    confirmDialogOpen,
    closeConfirmModal,
    handleConfirmButtonClick,
    buySellInfo,
  } = props;
  
  const handelQuestion = () => {
    if (buySellInfo.cancelAll === true && buySellInfo.cancelAll !== undefined) {
      if (buySellInfo.agreement === "cancel buy") {
        return (
          <Typography
            className={classes.header}
            style={{
              margin: "10px auto",
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            Please confirm cancellation of all buy
          </Typography>
        );
      } else if (buySellInfo.agreement === "cancel sell") {
        return (
          <Typography
            className={classes.header}
            style={{
              margin: "10px auto",
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            Please confirm cancellation of all sell
          </Typography>
        );
      } else {
        return (
          <Typography
            className={classes.header}
            style={{
              margin: "10px auto",
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            Please confirm cancellation of all
          </Typography>
        );
      }
    } else {
      if (buySellInfo.price === "" || buySellInfo.price === "0") {
        return (
          <Typography
            className={classes.header}
            style={{
              margin: "10px auto",
              textAlign: "center",
              color: "#FFFFFF",
            }}
          >
            Please confirm cancellation
          </Typography>
        );
      } else {
        if (buySellInfo !== "") {
          return (
            <>
              <Typography
                className={classes.header}
                style={{
                  margin: "10px auto",
                  textAlign: "center",
                  color: "#FFFFFF",
                }}
              >
                Please confirm{" "}
                {buySellInfo.agreement?.charAt(0).toUpperCase() +
                  buySellInfo.agreement?.slice(1)}{" "}
                : {buySellInfo.bondName}{" "}
              </Typography>
              <Typography
                className={classes.header}
                style={{
                  margin: "10px auto",
                  textAlign: "center",
                  color: "#FFFFFF",
                }}
              >
                Size {buySellInfo.price}
              </Typography>
            </>
          );
        }
      }
    }
  };
  return (
    <Dialog
      id="confirmDialog"
      open={confirmDialogOpen}
      classes={{ paper: classes.dialog }}
      onClose={closeConfirmModal}
      onKeyDown={(event) => {
        if (confirmDialogOpen === true) {
          if (event.key === "Enter") {
            const currentTime = new Date().getTime();
            const timeSinceLastEnterPress = currentTime - lastEnterPressTime;

            // Allow Enter key press only if more than, say, 500 milliseconds have passed
            if (timeSinceLastEnterPress > 500) {
              handleConfirmButtonClick("Confirm");
              setLastEnterPressTime(currentTime);
            }
          }
        }
      }}
    >
      <Grid container style={{ margin: "auto 0" }}>
        {/* <Grid item xs={12}> */}
          <Grid item xs={12} style={{ marginBottom: "30px" }}>
            <Typography className={classes.header}>
              Confirm Auction Transaction
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "50px" }}>
            {/* <Grid item style={{ marginLeft: "10px", marginTop: "20px" }}> */}
            {handelQuestion()}
            {/* </Grid> */}
          </Grid>
          <Grid
            item
            xs={12}
            container
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item >
              <Button
                className={classes.cancelButton}
                onClick={() => handleConfirmButtonClick("Cancel")}
              >
                <Typography style={{ color: "#FFFFFF" }}>Cancel</Typography>
              </Button>
            </Grid>
            <Grid item >
              <Button
                className={classes.confirmButton}
                onClick={(event) => {
                  event.currentTarget.disabled = true;
                  handleConfirmButtonClick("Confirm");
                }}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        {/* </Grid> */}
      </Grid>
    </Dialog>
  );
};

export default ConfirmationDialogView;
