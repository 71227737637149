import React, { useEffect, useState } from "react";
import ClientInfoView from "./ClientInfo.view";
import Api from "../../../../apis/clientRequest";
import NewEditClientDialog from "../Dialogs/NewEditClientDialog/NewEditClientDialog";
import PapaParse from "papaparse";
import moment from "moment";
import * as userSlice from "../../../../store/slice/userSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const ClientInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const [clients, setClients] = useState();
  const [sortedClients, setSortedClients] = useState();
  const [loadingClients, setLoadingClients] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("new");
  const [chosenClientId, setChosenClientId] = useState();

  const getAllClient = async () => {
    try {
      const result = await Api.getAllClients(token);
      if (result.data?.length > 0) {
        setClients(result.data);
        setSortedClients(result.data);
        setLoadingClients(false);
        dispatch(userSlice.updateClients(result.data));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    setDialogOpen(false);
  };

  const updateClients = async (newClientObj) => {
    await getAllClient();
    setDialogOpen(false);
  };

  const openNewOrEditDialog = (clientId) => {
    if (clientId) {
      setChosenClientId(clientId);
      setDialogType("edit");
    } else {
      setDialogType("new");
    }
    setDialogOpen(true);
  };

  const createNewClient = () => {
    setChosenClientId();
    setDialogType("new");
    setDialogOpen(true);
  };

  const filterClients = (e) => {
    if (e.target.value === "") {
      setSortedClients(clients);
    } else {
      let filtered = clients.filter((client) => {
        const searchTerm = e.target.value.toLowerCase();
        for (const key in client) {
          if (client[key]?.toString().toLowerCase().includes(searchTerm)) {
            return true;
          }
        }
        return false;
      });
      setSortedClients(filtered);
    }
  };

  const downloadClientCsvFile = () => {
    const csv = PapaParse.unparse(sortedClients);
    let blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(
        blob,
        `Bonds_Clients_${moment(new Date()).format("YYYY-MM-DD")}`
      );
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) {
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `Bonds_Clients_${moment(new Date()).format("YYYY-MM-DD")}`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  useEffect(() => {
    getAllClient();
  }, []);

  return (
    <>
      <ClientInfoView
        clients={clients}
        sortedClients={sortedClients}
        filterClients={filterClients}
        createNewClient={createNewClient}
        loadingClients={loadingClients}
        openNewOrEditDialog={openNewOrEditDialog}
        downloadClientCsvFile={downloadClientCsvFile}
      />
      <NewEditClientDialog
        updateClients={updateClients}
        dialogType={dialogType}
        closeModal={closeModal}
        dialogOpen={dialogOpen}
        clientId={chosenClientId}
      />
    </>
  );
};

export default ClientInfo;
