
import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  forgotPasswordPaper: {
    padding: "50px 0px 50px 0px",
    width: "468px",
    background: "#262626 0% 0% no-repeat padding-box !important",
    boxShadow: "1px 1px 7px #00000091 !important",
    opacity: 1,
    background: "#262626 !important",
  },
  otpContainer: {
    padding: "50px 0px 50px 0px",
    width: "460px",
    background: "#262626 0% 0% no-repeat padding-box !important",
    boxShadow: "1px 1px 7px #00000091 !important",
    opacity: 1,
    background: "#262626 !important",
  },

  signInToggleBtn: {
    width: 324,
    height: 86,
    background: "#303030 !important",
    color: "#ffffff !important",
    textTransform: "none",
    borderRadius: 8,
    "& .MuiButton-label": {
      height: "100%",
    },
  },
  authButton: {
    width: "208px",
    height: 30,
    fontSize: "1.167rem",
    background: "#54A9FF !important",
    color: "#000000 !important",
    textTransform: "none",
    borderRadius: 4,
    "& .MuiButton-label": {
      height: "100%",
    },
    "&:hover": {
      background: "#1565c0 !important",
    },
  },
   verifyButton: {
    width: 100,
    height: 30,
    background: "#1b86ff !important",
    color: "#ffffff !important",
    textTransform: "none",
    borderRadius: 8,
    "& .MuiButton-label": {
      height: "100%",
    },
    "&:hover": {
      background: "#1565c0 !important",
    },
  },
}));
